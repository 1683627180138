import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {GeneratePdfReportDialogData} from "./generate-pdf-report-dialog-data";
import {GeneratePdfReportUiDefinition} from "./generate-pdf-report-ui-definition";
import {MatDividerModule} from "@angular/material/divider";
import {PdfReportRequestParam} from "../../../model/pdf-report/pdf-report-request-param";
import {UserService} from "../../../../shared/service/user.service";
import {PdfReportTypeEnum} from "../../../enum/pdf-report-type-enum";
import {LoggerService} from "../../../../shared/service/log/logger.service";
import {GeneratePdfClientReportComponent} from "./generate-pdf-client-report/generate-pdf-client-report.component";
import {PdfReportMyPropertyReportConfiguration} from "../../../model/pdf-report/input-param/pdf-report-my-property-report-configuration";
import {PdfReportBasicReportConfiguration} from "../../../model/pdf-report/input-param/pdf-report-basic-report-configuration";
import {PdfReportClientReportConfiguration} from "../../../model/pdf-report/input-param/pdf-report-client-report-configuration";
import {UserAccessControl} from "../../../model/user/user-access-control";
import {lastValueFrom} from "rxjs";
import {DemographicsService} from "../../../../shared/service/demographics.service";
import {MainMapService} from "../../../../home/home/main-map/main-map.service";
import {GeneratePdfPropertyReportComponent} from "./generate-pdf-property-report/generate-pdf-property-report.component";
import {PropertyDetail} from "../../../model/property/property-detail";
import {PropertyReportSearchService} from "../../../../shared/service/search/property-report-search.service";
import {DataService} from "../../../../shared/service/data.service";
import _ from "lodash";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";


@Component({
  selector: 'gema3g-generate-pdf-report',
  standalone: true,
  imports: [
    MatDialogModule,
    MatDividerModule,
    GeneratePdfClientReportComponent,
    GeneratePdfPropertyReportComponent,
    MatProgressSpinnerModule
  ],
  templateUrl: './generate-pdf-report.component.html',
  styleUrl: './generate-pdf-report.component.scss'
})
export class GeneratePdfReportComponent {
  userAccessControls: UserAccessControl;
  uiData: GeneratePdfReportUiDefinition[] = [];
  mainPageSummary: string;
  buttonIsDisabled: boolean;
  isLoading: boolean = true;
  propertyDetail: PropertyDetail;

  constructor(public dialogRef: MatDialogRef<GeneratePdfReportComponent>,
              @Inject(MAT_DIALOG_DATA) public data: GeneratePdfReportDialogData,
              private userService: UserService,
              private loggerService: LoggerService,
              private demographicsService: DemographicsService,
              private mainMapService: MainMapService,
              private propertyReportSearchService: PropertyReportSearchService,) {
    this.userAccessControls = this.userService.getUserAccessControl();
    this.propertyDetail = this.data.propertyDetail;
    this.loadUi();
  }

  async checkAccess() {
    if (this.userAccessControls?.propertyReportDemographicsAccess) {
      const demographicsData = await lastValueFrom(this.demographicsService.getDemographicsDataForPropertyDetail(this.data.propertyDetail));
      this.data.propertyDetail.hasDemographicsData = !_.isEmpty(demographicsData);
    }
    if(this.data.pinOrArn.pin){
      await this.mainMapService.isStreetViewAvailableForPin(this.data.pinOrArn.pin).then(
        isAvailable => this.data.propertyDetail.isStreetViewAvailable = isAvailable
      );
    }
  }

  async loadUi(selectedIndex? : number) {
    this.isLoading = true;
    try {
      this.uiData = [];
      await this.checkAccess();
      this.mainPageSummary = this.data.comparableSales ? DataService.PDF_REPORT_MAIN_PAGE_SUMMARY_FOR_COMPARABLE_SALES : DataService.PDF_REPORT_MAIN_PAGE_SUMMARY_FOR_PROPERTY;

      const clientReportReportParam = new PdfReportClientReportConfiguration(this.userService?.getUserPreferencesFromLocalStorage(),
        this.propertyDetail,
        this.userAccessControls,
        this.data.comparableSales);
      clientReportReportParam.propertyInfo.ownership = false;
      clientReportReportParam.propertyInfo.legalDescription = false;


      this.uiData.push(new GeneratePdfReportUiDefinition({
        radioButtonLabel: 'Basic Client Report',
        visible: !this.data.openFromComparablesReport,
        description: DataService.PDF_REPORT_BASIC_REPORT_DESCRIPTION,
        customizable: false,
        selected: !this.data.comparableSales,
        type: PdfReportTypeEnum.BASIC,
        reportParam: new PdfReportBasicReportConfiguration(this.userService?.getUserPreferencesFromLocalStorage(),
          this.propertyDetail,
          this.userAccessControls,
          this.data.comparableSales)
      }));
      this.uiData.push(new GeneratePdfReportUiDefinition({
        radioButtonLabel: this.data.comparableSales ? DataService.PDF_REPORT_MY_REPORT_REPORT_NAME_FOR_COMPARABLE_SALES : DataService.PDF_REPORT_MY_REPORT_REPORT_NAME_FOR_PROPERTY,
        visible: true,
        description: this.data.comparableSales ? DataService.PDF_REPORT_MY_REPORT_DESCRIPTION_FOR_COMPARABLE_SALES : DataService.PDF_REPORT_MY_REPORT_DESCRIPTION_FOR_PROPERTY,
        customizable: true,
        selected: this.data.comparableSales,
        type: PdfReportTypeEnum.MY_PROPERTY_REPORT,
        reportParam: new PdfReportMyPropertyReportConfiguration(this.userService?.getUserPreferencesFromLocalStorage(),
          this.propertyDetail,
          this.userAccessControls,
          this.data.comparableSales)
      }));
      this.uiData.push(new GeneratePdfReportUiDefinition({
        radioButtonLabel: 'Client Report',
        visible: true,
        description: this.data.comparableSales ? DataService.PDF_REPORT_CLIENT_REPORT_DESCRIPTION_FOR_COMPARABLE_SALES : DataService.PDF_REPORT_CLIENT_REPORT_DESCRIPTION_FOR_PROPERTY,
        customizable: true,
        selected: false,
        type: PdfReportTypeEnum.CLIENT_REPORT,
        reportParam: clientReportReportParam
      }));

      // if a reload happened go back to previous selection
      if (_.isNumber(selectedIndex)) {
        this.selectPdfReport(this.uiData[selectedIndex]);
        this.openCustomize(this.uiData[selectedIndex]);
      }
    }
    finally {
      this.isLoading = false;
    }
  }

  submitPdfReportGenerateRequest() {
    let pdfReportRequestParam: PdfReportRequestParam = new PdfReportRequestParam(undefined, this.data.pinOrArn.pin, this.data.pinOrArn.arn);
    try {
      const reportSelection = this.uiData.filter(value => value.selected)[0];
      if (reportSelection) {
        this.loggerService.logDebug("Running " + reportSelection.radioButtonLabel);
        pdfReportRequestParam.requestType = reportSelection.type;

        pdfReportRequestParam.loadFromReportParam(reportSelection.reportParam, this.userAccessControls, this.data.comparableSales);
      }
    }
    catch (ex){
      this.loggerService.logError(ex);
    }

    // @ts-ignore
    this.dialogRef.close(pdfReportRequestParam);
  }

  selectPdfReport(pdfReport: GeneratePdfReportUiDefinition) {
    this.buttonIsDisabled = pdfReport.type === PdfReportTypeEnum.CLIENT_REPORT && this.data.openFromComparablesReport && this.propertyDetail?.isEmpty;
    this.uiData.forEach(item => item.selected = pdfReport.radioButtonLabel == item.radioButtonLabel);
    if (this.buttonIsDisabled) {
      this.openCustomize(pdfReport);
    }
  }

  openCustomize(pdfReport: GeneratePdfReportUiDefinition) {
    this.uiData.forEach(item => item.expanded = pdfReport.radioButtonLabel == item.radioButtonLabel);
  }

  collapseAdditionalParams(generatePdfReportUiDefinition: GeneratePdfReportUiDefinition) {
    if(generatePdfReportUiDefinition){
      generatePdfReportUiDefinition.expanded = false;
    }
  }

  goToComparableSales() {
    this.dialogRef.close({openComparableSales : true});
  }

  reloadProperty(){

  }

  async goReloadProperty(pin: string) {
    if(pin) {
      this.propertyDetail = await lastValueFrom(this.propertyReportSearchService.getPropertyDetailByPin(pin));
      if(this.propertyDetail){
        this.data.pinOrArn.pin = this.propertyDetail.pii?.pin;
        this.data.pinOrArn.arn = this.propertyDetail.pii?.arn;
        const currentSelection = this.uiData?.findIndex(value => value.selected);
        await this.loadUi(currentSelection);
      } else{
        this.loggerService.logError(`Could not reload PDF report modal. Search for pin ${pin} did not return valid data.`);
      }
    } else {
      this.loggerService.logError(`Empty selection on last visited properties`);
    }
  }
}
