import {AfterViewInit, Component, ElementRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, signal, SimpleChanges, ViewChild, WritableSignal} from '@angular/core';
import { PropertyReportService } from "../../../shared/service/property-report.service";
import { StreetViewPoint } from "../../../core/model/spatial/street-view-point";
import { take, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { defaultErrorMatSnackBarConfig } from "../../../shared/constant/constants";
import { LoggerService } from '../../../shared/service/log/logger.service';
import { StreetViewService } from '../../../shared/service/google-maps/street-view.service';
import { BaseUnsubscribe } from '../../../core/component/base-unsubscribe/base-unsubscribe';
import { DataService } from '../../../shared/service/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'gema3g-geo-address-map-overlay',
  templateUrl: './geo-address-map-overlay.component.html',
  styleUrls: ['./geo-address-map-overlay.component.scss']
})
export class GeoAddressMapOverlayComponent extends BaseUnsubscribe implements AfterViewInit, OnChanges {

  @ViewChild('mapContainer', { static: false }) mapContainer: ElementRef;
  @Input() streetViewPosition: google.maps.LatLng;
  @Input() addressQueryResult: any;
  @Input() addressUpdating: boolean;
  @Output() addressOverlayMapLocationChanged = new EventEmitter();
  map: google.maps.Map;
  pin: string;
  calculatedAddress: WritableSignal<string> = signal('');
  addressCoordinates: google.maps.LatLng = DataService.TORONTO_COORDINATES;
  addressNotAvailable: string = DataService.STREETVIEW_ADDRESS_NOT_AVAILABLE;
  marker: google.maps.Marker = new google.maps.Marker({});
  defaultMarkerSize: google.maps.Size = new google.maps.Size(32, 32);
  defaultMarkerIcon: string = 'assets/img/svg/map/icon_svlocation.svg';

  constructor() {
    super();
  }

  private propertyReportService: PropertyReportService = inject(PropertyReportService);
  private streetViewService: StreetViewService = inject(StreetViewService);
  private loggerService: LoggerService = inject(LoggerService);
  private _snackBar: MatSnackBar = inject(MatSnackBar);

  initializeAddressMap = () => {
    this.loggerService.logDebug('initializing address map overlay');

    let mapOptions = {
      center: this.addressCoordinates,
      zoom: 15,
      streetViewControl: false,
      overviewMapControl: true,
      panControl: true,
      //maxZoom: 21,
      //minZoom: 1,
      disableDefaultUI: true,
      visualEnabled: true,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: true,

      zoomControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_RIGHT
      }
    }

    try {
      this.map = new google.maps.Map(this.mapContainer.nativeElement, mapOptions);

      this.map.addListener("click", (event: google.maps.MapMouseEvent) => {
        this.rePositionStreetView(event.latLng!);
      });

      let mapControls = this.addMapControls();
      // @ts-ignore
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(mapControls);

      this.setAddressMarker();

    } catch (e) {
      this.loggerService.logError('error initializing address map overlay', e);
    }
  }

  rePositionStreetView = async (latLng: google.maps.LatLng) => {
    await this.streetViewService.isStreetViewServiceAvailable(latLng.lat(), latLng.lng())
    .then(
      (isAvailable) => {
        if (isAvailable) {
          this.loggerService.logInfo(`new address map overlay location`, latLng);
          this.addressCoordinates = latLng;
          this.setAddressMarker();
          this.addressOverlayMapLocationChanged.emit(this.addressCoordinates);
        } else {
          this.openSnackBarError(DataService.STREETVIEW_NOT_AVAILABLE);
        }
      }
    )
    .catch(err => {
      this.openSnackBarError(DataService.STREETVIEW_NOT_AVAILABLE);
    })
  }

  setAddressMarker = () => {
    if (this.marker) this.marker.setMap(null);
    this.marker = new google.maps.Marker({});

    let markerIcon = {
      url: this.defaultMarkerIcon,
      scaledSize: this.defaultMarkerSize
    }

    this.marker = new google.maps.Marker({
      map: this.map,
      position: this.addressCoordinates,
      icon: markerIcon
    });
  }

  openSnackBarError(msg: string) {
    this._snackBar.open(msg, 'Close', defaultErrorMatSnackBarConfig);
  }

  async ngAfterViewInit() {
    this.initializeAddressMap();

    this.streetViewService.geoAddressMapOverlayCoordinates$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((coordinates: google.maps.LatLng) => {
        this.addressCoordinates = coordinates;

        if (!this.map.getBounds()?.contains(this.addressCoordinates)) {
          this.map.panTo(this.addressCoordinates);
        }

        this.setAddressMarker();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    let position: google.maps.LatLng = changes['streetViewPosition']?.currentValue;
    if (position) {
      this.addressCoordinates = position;
      if (this.addressCoordinates) {
        if (this.map) {
          setTimeout(() => {
            this.setAddressMarker();
            this.map.panTo(this.addressCoordinates);
          }, 100);
        }
      }
    }

    let addressQueryResult = changes['addressQueryResult']?.currentValue;
    this.updateAddress(addressQueryResult);
  }

  updateAddress(addressQueryResult:any){
    if (addressQueryResult) {
      let address = addressQueryResult.address?.message;
      let pin = addressQueryResult.pin;
      let error = addressQueryResult.address?.errorMessage;

      if (_.isEmpty(address) || !_.isEmpty(error)) {
        this.calculatedAddress.set(DataService.STREETVIEW_ADDRESS_NOT_AVAILABLE);
        this.pin = DataService.NOT_APPLICABLE;
      } else {
        this.calculatedAddress.set(address);
        this.pin = pin;
      }

      this.updateUI();

      this.loggerService.logDebug(`address map overlay address: ${this.calculatedAddress()}, pin: ${this.pin}`);
    }
  }

  addMapControls(): HTMLDivElement {
    const mainDiv = document.createElement('div');
    mainDiv.id = `overlay-zoom-controls`;
    mainDiv.className = 'map-top-right-controls';

    const zoomInDiv = document.createElement('div');
    zoomInDiv.id = `overlay-zoom-in`;
    zoomInDiv.className = 'zoom-in-black';
    zoomInDiv.addEventListener('click', () => { this.zoomIn(); });
    const zoomInImg = document.createElement('img');
    zoomInImg.src = `/assets/img/svg/icons/icon_map_zoom_plus.svg`;
    zoomInDiv.appendChild(zoomInImg);
    mainDiv.appendChild(zoomInDiv);

    const zoomOutDiv = document.createElement('div');
    zoomOutDiv.id = `overlay-zoom-out`;
    zoomOutDiv.className = 'zoom-out-black';
    zoomOutDiv.addEventListener('click', () => { this.zoomOut(); });
    const zoomOutImg = document.createElement('img');
    zoomOutImg.src = `/assets/img/svg/icons/icon_map_zoom_minus.svg`;
    zoomOutDiv.appendChild(zoomOutImg);
    mainDiv.appendChild(zoomOutDiv);

    return mainDiv;
  }

  zoomIn() {
    // @ts-ignore
    this.map?.setZoom(this.map.getZoom() + 1);
  }

  zoomOut() {
    // @ts-ignore
    this.map?.setZoom(this.map.getZoom() - 1);
  }

  openPropertyReport = (pin: string) => {
    this.propertyReportService.showPropertyReportByPinDeferData(pin);
  }

  closeStreetView() {
    this.propertyReportService.requestPropertyStreetView(new StreetViewPoint());
  }
}
