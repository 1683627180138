<div class="gema3g-ao-container">
  <div class="gema3g-ao-container__inner">

    <div class="col-0">
      <mat-card>
        <mat-card-content>
          <ng-container *ngIf="(user$ | async) as user; else loading">
            <ng-container *ngIf="user.licenseLength >= 1">
              <div *ngFor="let item of user.licenses | keyvalue">
                <div class="geo-flex-row">
                  <div class="flex-column-left">
                    <div class="left">
                      <div class="logo-img">
                        <img id="sub-gwh-logo-{{item.value.licenseCategory}}" *ngIf="(item.value.licenseCategory=='GWH_RENEWAL')" alt="GEOWAREHOUSE" src="assets/img/geowarehouse-logo1.png">
                        <img id="sub-hv-logo-{{item.value.licenseCategory}}" *ngIf="(item.value.licenseCategory=='HVR_SUBSCRIPTION')" alt="HOME VERIFIED REPORT" src="assets/img/home-verified-logo.png"/>
                      </div>
                      @if (user?.userProfile?.parentREB == false) {
                        <h5 id="sub-h5-sed-{{item.value.licenseCategory}}">{{ item.value.licenseEndDate | date:'MMM d, y' }}<span id="sub-sed-label">Subscription Ends</span></h5>
                        @if (item.value.licenseCategory === 'GWH_RENEWAL' && userAccessControls?.geowarehouseSubscriptionRenewalAccess == true && item.value.licenseAvailableForRenew) {
                          <div>
                            <a (click)="renewSubscription()"
                               class="btn btn-primary mat-green license-btn"
                               id="account-menu-ext-{{item.key}}">
                              Renew
                            </a>
                          </div>
                        }
                        <div class="pt-4">
                          <p id="sub-time-left-{{item.value.licenseCategory}}"> {{ timeLeftDate(item.value) }} </p>
                        </div>
                      }
                    </div>
                  </div>
                  <div class="flex-column-right">
                    <div class="right">
                      <div class="sub-details">
                        <h6 id="sub-h6-details-{{item.value.licenseCategory}}">Subscription Details</h6>
                        <table id="sub-tbl-{{item.value.licenseCategory}}" class="sub-table">
                          @if (userLicenseCounters$ | async; as licenseCounters) {
                            @if (licenseCounters?.comparablesSearchCounter && !!user?.userProfile?.licensePackageId) {
                              <tr id="sub-tbl-row-included-cr">
                                <td id="sub-tbl-hdr-included-cr">Included</td>
                                <td id="sub-tbl-data-included-cr">
                                  <span>{{ licenseCounters?.comparablesSearchCounter?.licenseTotal }} Comparable Reports</span>
                                </td>
                              </tr>
                            }
                          }
                          <tr id="sub-tbl-row-included-{{item.value.licenseCategory}}">
                            <td id="sub-tbl-hdr-included-{{item.value.licenseCategory}}">Included</td>
                            <td id="sub-tbl-data-included-{{item.value.licenseCategory}}">
                              @if (item.value.licenseCountUnlimited) {
                                <span>Unlimited</span>
                              } @else {
                                <span>{{ item.value.licenseCountMax }}</span>
                              }
                              Property Reports
                            </td>
                          </tr>
                          <tr id="sub-tbl-row-sd-{{item.value.licenseCategory}}">
                            <td id="sub-tbl-hdr-sd-{{item.value.licenseCategory}}">Start Date</td>
                            <td id="sub-tbl-data-sd-{{item.value.licenseCategory}}">{{ item.value.created | date:'MMM d, y' }}</td>
                          </tr>
                          @if (user?.userProfile?.parentREB == false) {
                            <tr id="sub-tbl-row-ed-{{item.value.licenseCategory}}">
                              <td id="sub-tbl-hdr-ed-{{item.value.licenseCategory}}">End Date</td>
                              <td id="sub-tbl-data-ed-{{item.value.licenseCategory}}"> {{ item.value.licenseEndDate | date:'MMM d, y' }}</td>
                            </tr>
                          }

                        </table>

                      </div>

                      <div class="display-flex py-7">
                        <div>
                          <h4 id="sub-h4-usage-{{item.value.licenseCategory}}">Usage</h4>
                        </div>
                        <div class="flex-1"></div>
                        <div>
                          <a id="sub-link-details-{{item.value.licenseCategory}}" class="anchor" routerLink="../report-history">Details</a>
                        </div>
                      </div>

                      <h6 id="sub-h6-pr-{{item.value.licenseCategory}}">Property Reports</h6>
                      <ng-container *ngIf="user">
                        <section class="progress-bar">
                          <mat-progress-bar
                            id="sub-progress-bar-usage-{{item.value.licenseCategory}}"
                            [value]="user.reportsUsedSoFar"
                            color="primary"
                            mode="determinate"
                          >
                          </mat-progress-bar>
                        </section>
                      </ng-container>
                      <ng-template #loading>Loading...</ng-template>
                      <div *ngIf="(user$ | async) as user;" class="display-flex pt-1">
                        <div>
                          <h6 id="sub-h4-used-{{item.value.licenseCategory}}"><span class="text-os-bold">{{ user.totalReportsUsed }}</span> Used</h6>
                        </div>
                        <div class="flex-1"></div>
                        <div>
                          <h6 id="sub-h4-total-{{item.value.licenseCategory}}"><span class="text-os-bold">{{ user.totalReports }}</span> Total</h6>
                        </div>
                      </div>

                      @if (userLicenseCounters$ | async; as licenseCounters) {
                        @if (user$ | async; as user) {
                          @if (licenseCounters?.comparablesSearchCounter && !!user?.userProfile?.licensePackageId) {
                            <h6 id="sub-h6-scr" class="pt-2">Search Comparable Reports</h6>
                            <section class="progress-bar">
                              <mat-progress-bar
                                id="sub-progress-bar-usage-cr"
                                [value]="licenseCounters?.comparablesSearchCounter?.percentageOfReportsUsedSoFar"
                                color="primary"
                                mode="determinate"
                              >
                              </mat-progress-bar>
                            </section>
                            <ng-template #loading>Loading...</ng-template>
                            <div class="display-flex pt-1 pb-3">
                              <div>
                                <h6 id="sub-h4-used-cr"><span class="text-os-bold">{{ licenseCounters?.comparablesSearchCounter?.licenseUsed }}</span> Used</h6>
                              </div>
                              <div class="flex-1"></div>
                              <div>
                                <h6 id="sub-h4-total-cr"><span class="text-os-bold">{{ licenseCounters?.comparablesSearchCounter?.licenseTotal }}</span> Total</h6>
                              </div>
                            </div>
                          }
                        }
                      }
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #loading>Loading...</ng-template>
        </mat-card-content>
      </mat-card>
    </div>


    <div class="col-1">
      <gema3g-need-assistance></gema3g-need-assistance>
    </div>

  </div>
</div>




