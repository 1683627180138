import {MatSnackBarConfig} from "@angular/material/snack-bar";
import { DataService } from "../../shared/service/data.service";

const INFINITY: number = DataService.SEARCH_COMPARABLES_INFINITY;
const INFINITY_STRING: string = String(INFINITY);
const INFINITY_VALUE_DISPLAY: string = DataService.SEARCH_COMPARABLES_INFINITY_VALUE_DISPLAY;

export const minPriceRange =
  [{
    "id": 0,
    "value": "0"
  },
    {
      "id": 100000,
      "value": "100,000"
    },
    {
      "id": 200000,
      "value": "200,000"
    },
    {
      "id": 300000,
      "value": "300,000"
    },
    {
      "id": 400000,
      "value": "400,000"
    },
    {
      "id": 500000,
      "value": "500,000"
    },
    {
      "id": 600000,
      "value": "600,000"
    },
    {
      "id": 700000,
      "value": "700,000"
    },
    {
      "id": 800000,
      "value": "800,000"
    },
    {
      "id": 900000,
      "value": "900,000"
    },
    {
      "id": 1000000,
      "value": "1,000,000"
    }];

export const maxPriceRange =
  [{
    "id": 200000,
    "value": "200,000"
  },
    {
      "id": 300000,
      "value": "300,000"
    },
    {
      "id": 400000,
      "value": "400,000"
    },
    {
      "id": 500000,
      "value": "500,000"
    },
    {
      "id": 600000,
      "value": "600,000"
    },
    {
      "id": 700000,
      "value": "700,000"
    },
    {
      "id": 800000,
      "value": "800,000"
    },
    {
      "id": 900000,
      "value": "900,000"
    },
    {
      "id": 1000000,
      "value": "1,000,000"
    },
    {
      "id": 1500000,
      "value": "1,500,000"
    },
    {
      "id": INFINITY,
      "value": INFINITY_VALUE_DISPLAY
    }];

export const lotSizeValues = [{
  id: 1,
  value: '0',
  metricValue: '0',
  imperialValue: '0',
  metricDisplayValue: '0',
  imperialDisplayValue: '0'
}, {
  id: 2,
  value: '100',
  metricValue: '100',
  metricDisplayValue: '100',
  imperialValue: '1000',
  imperialDisplayValue: '1,000 sqft'
}, {
  id: 3,
  value: '500',
  metricValue: '500',
  metricDisplayValue: '500',
  imperialValue: '2000',
  imperialDisplayValue: '2,000 sqft'
}, {
  id: 4,
  value: '1000',
  metricValue: '1000',
  metricDisplayValue: '1,000',
  imperialValue: '5000',
  imperialDisplayValue: '5,000 sqft'
}, {
  id: 5,
  value: '1500',
  metricValue: '1500',
  metricDisplayValue: '1,500',
  imperialValue: '10000',
  imperialDisplayValue: '10,000 sqft'
}, {
  id: 6,
  value: '2000',
  metricValue: '2000',
  metricDisplayValue: '2,000',
  imperialValue: '10890',
  imperialDisplayValue: '.25 acre'
}, {
  id: 7,
  value: '5000',
  metricValue: '5000',
  metricDisplayValue: '5,000',
  imperialValue: '21780',
  imperialDisplayValue: '.5 acre'
}, {
  id: 8,
  value: '10000',
  metricValue: '10000',
  metricDisplayValue: '10,000',
  imperialValue: '43560',
  imperialDisplayValue: '1 acre'
}, {
  id: 9,
  value: '15000',
  metricValue: '15000',
  metricDisplayValue: '15,000',
  imperialValue: '87120',
  imperialDisplayValue: '2 acres'
}, {
  id: 10,
  value: '30000',
  metricValue: '30000',
  metricDisplayValue: '30,000',
  imperialValue: '130680',
  imperialDisplayValue: '3 acres'
}, {
  id: 11,
  value: '50000',
  metricValue: '50000',
  metricDisplayValue: '50,000',
  imperialValue: '217800',
  imperialDisplayValue: '5 acres'
}, {
  id: 12,
  value: '100000',
  metricValue: '100000',
  metricDisplayValue: '100,000',
  imperialValue: '435600',
  imperialDisplayValue: '10 acres'
}, {
  id: 13,
  value: '500000',
  metricValue: '500000',
  metricDisplayValue: '500,000',
  imperialValue: '1089000',
  imperialDisplayValue: '25 acres'
}, {
  id: 14,
  value: '1000000',
  metricValue: '1000000',
  metricDisplayValue: '1,000,000',
  imperialValue: '4356000',
  imperialDisplayValue: '100 acres'
}, {
  id: 15,
  value: '1000000+',
  metricsValue: INFINITY_STRING,
  metricDisplayValue: '1,000,000+',
  imperialValue: INFINITY_STRING,
  imperialDisplayValue: '100+ acres'
}];

//the value of the value attribute is converted from the imperial display value
export const comparablesLotSizeValues = [{
  id: 1,
  value: '0',
  metricValue: '0',
  imperialValue: '0',
  metricDisplayValue: '0',
  imperialDisplayValue: '0'
}, {
  id: 2,
  value: '92.903',
  metricValue: '100',
  metricDisplayValue: '100',
  imperialValue: '1000',
  imperialDisplayValue: '1,000 sqft'
}, {
  id: 3,
  value: '185.8061',
  metricValue: '500',
  metricDisplayValue: '500',
  imperialValue: '2000',
  imperialDisplayValue: '2,000 sqft'
}, {
  id: 4,
  value: '464.5152',
  metricValue: '1000',
  metricDisplayValue: '1,000',
  imperialValue: '5000',
  imperialDisplayValue: '5,000 sqft'
}, {
  id: 5,
  value: '929.0304',
  metricValue: '1500',
  metricDisplayValue: '1,500',
  imperialValue: '10000',
  imperialDisplayValue: '10,000 sqft'
}, {
  id: 6,
  value: '1011.71',
  metricValue: '2000',
  metricDisplayValue: '2,000',
  imperialValue: '10890',
  imperialDisplayValue: '.25 acre'
}, {
  id: 7,
  value: '2023.43',
  metricValue: '5000',
  metricDisplayValue: '5,000',
  imperialValue: '21780',
  imperialDisplayValue: '.5 acre'
}, {
  id: 8,
  value: '4046.86',
  metricValue: '10000',
  metricDisplayValue: '10,000',
  imperialValue: '43560',
  imperialDisplayValue: '1 acre'
}, {
  id: 9,
  value: '8093.71',
  metricValue: '15000',
  metricDisplayValue: '15,000',
  imperialValue: '87120',
  imperialDisplayValue: '2 acres'
}, {
  id: 10,
  value: '12140.6',
  metricValue: '30000',
  metricDisplayValue: '30,000',
  imperialValue: '130680',
  imperialDisplayValue: '3 acres'
}, {
  id: 11,
  value: '20234.3',
  metricValue: '50000',
  metricDisplayValue: '50,000',
  imperialValue: '217800',
  imperialDisplayValue: '5 acres'
}, {
  id: 12,
  value: '40468.6',
  metricValue: '100000',
  metricDisplayValue: '100,000',
  imperialValue: '435600',
  imperialDisplayValue: '10 acres'
}, {
  id: 13,
  value: '101171',
  metricValue: '500000',
  metricDisplayValue: '500,000',
  imperialValue: '1089000',
  imperialDisplayValue: '25 acres'
}, {
  id: 14,
  value: '404686',
  metricValue: '1000000',
  metricDisplayValue: '1,000,000',
  imperialValue: '4356000',
  imperialDisplayValue: '100 acres'
}, {
  id: 15,
  value: INFINITY_STRING,
  metricValue: INFINITY_STRING,
  metricDisplayValue: '1,000,000+',
  imperialValue: INFINITY_STRING,
  imperialDisplayValue: '100+ acres'
}];


export const defaultErrorMatSnackBarConfig: MatSnackBarConfig = {
  horizontalPosition: 'center',
  verticalPosition: 'top',
  duration: 5000,
  panelClass: ['red-snackbar']
};

export const defaultIndefiniteErrorMatSnackBarConfig: MatSnackBarConfig = {
  horizontalPosition: 'center',
  verticalPosition: 'top',
  duration: undefined,
  panelClass: ['red-snackbar']
};

export const defaultMatSnackBarConfig: MatSnackBarConfig = {
  horizontalPosition: 'center',
  verticalPosition: 'top',
  duration: 5000,
  panelClass: ['green-snackbar']
};

export const defaultResponsiveOptions: any[] = [
  {
    breakpoint: '1199px',
    breakpointNo: 1199,
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: '767px',
    breakpointNo: 767,
    numVisible: 1,
    numScroll: 1
  }
];

export const LocalStorageKey = {
  userLocalStorageKey: 'user',
  userPreferenceLocalStorageKey: 'user_preference',

  LRO: 'lro',

  FBS_MINIMUM_ZOOM_LEVEL_KEY: 'fbs_minimum_zoom_zevel',
  FBS_ENHANCED_AERIAL_MAP_SELECTED_KEY:  'fbs_aerial_map_selected',

  MAP_STANDARD_TYPE: 'map_standard_type',
  MAP_ENHANCED_AERIAL_TYPE: 'map_enhanced_aerial_type',
  MAP_AERIAL_TYPE_BIRDS_EYE_VIEW_ANGLE_TILT: 'map_aerial_bev_angle_tilt',
  MAP_AERIAL_TYPE_BIRDS_EYE_VIEW_LABEL: 'map_aerial_bev_label',

  INIT_PROFILE_SEARCH_METHOD_LOCAL_STORAGE_KEY : 'init_profile_search_method',
  INIT_PROFILE_LRO_LOCAL_STORAGE_KEY : 'init_profile_Lro',

  LOCAL_STORAGE_KEY : 'PdfReportRequestParam_LOCAL_STORAGE_KEY',

  LAST_LOGGED_IN: 'last_logged_in',
  LAST_PROPERTY_INDENTIFIER_VIEWED: 'last_property_identifier_viewed',

  activeTheme: 'theme',

  isOktaLogin: 'okta_login',
  auth0Token: 'authO_token',
  auth0ExpiresAt: 'authO_token_expires_at',
  auth0UserId: 'authO_user_id',
  auth0LoginAttempt: 'authO_login_attempt',

  comparablesSalesCounter: 'cs_counter',
  comparablesSalesSnapshot: 'cs_snapshot',
  comparablesSalesAbortRenderMarkers: 'cs_abort_markers',
  comparablesSalesResultsAutoPanMap: 'csr_auto_pan_map',

  // used for reloading the user access controls after a redirect to eStore that changes the user's access controls
  reloadUserAccessControls: 'reload_user_access_controls',
}

export const TITLE_CHECK_PROD_ID = '1207';
export const TILE_PLAN_IMAGE_PROD_ID = '133';
export const TILE_INSTRUMENT_IMAGE_PROD_ID = '134';

export const colourPalette = {
  sunrise: '#F78F1E'
}
