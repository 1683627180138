import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Assessment } from "../../../../../core/model/assessment/assessment";
import { UserAccessControl } from "../../../../../core/model/user/user-access-control";
import { MatTableDataSource } from "@angular/material/table";
import { BasicAssessment } from "../../../../../core/model/assessment/basic-assessment";
import { PhasedInAssessment } from "../../../../../core/model/mpac/phasedin-assessment";
import { MatSort } from '@angular/material/sort';
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {DataService} from "../../../../../shared/service/data.service";
import {SatPopoverComponent} from "@ncstate/sat-popover";

@Component({
  selector: 'gema3g-site-structure-four-year-phased-in',
  templateUrl: './site-structure-four-year-phased-in.component.html',
  styleUrls: ['./site-structure-four-year-phased-in.component.scss']
})
export class SiteStructureFourYearPhasedInComponent implements OnChanges{
  @Input() assessment: Assessment = new Assessment();
  @Input() userAccessControls: UserAccessControl;
  columns: string[] = ['year', 'value'];
  phasedInDataSource: MatTableDataSource<BasicAssessment>;
  sort: MatSort;
  phasedInDisclaimerPopupMessage = DataService.PHASED_IN_ASSESSMENT_DISCLAIMER;

  //This setter will fire once matSort in the view changes. I.e. when it is defined the first time. It will not fire when you change the sorting by clicking on the arrows.
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.sort = sort;
    this.setDataSort();
  }

  setDataSort = () => {
    if (this.phasedInDataSource) {
      this.phasedInDataSource.sort = this.sort;
    }

    if (this.sort) {
      this.sort.sort({
        id: 'year',
        start: 'asc',
        disableClear: true
      });
      this.sort.disableClear = true;
    }

    if (this.phasedInDataSource) {
      this.phasedInDataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'year':
            return item.taxYear;
          case 'value':
            return item.assessmentValue;
          default: // @ts-ignore
            return item[property];
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.userAccessControls.mpacEnhancedDetailsAccess ) {
      if (this.assessment?.enhancedAssessment?.assessment?.phaseIn) {
        this.phasedInDataSource = new MatTableDataSource<BasicAssessment>(this.assessment.enhancedAssessment.assessment.phaseIn);
      }
    } else if (this.userAccessControls.MPACSubscriptionPremiumOrEnhanced)  {
      if (this.assessment?.mpacAssessment?.property?.overallPhaseIn?.phaseIn) {
        this.phasedInDataSource = new MatTableDataSource<BasicAssessment>(this.assessment.mpacAssessment.property.overallPhaseIn.phaseIn);
      }
    }
  }

  protected readonly faCircleInfo = faCircleInfo;

  async delayedMouseOut(popover: SatPopoverComponent): Promise<void> {
    setTimeout(() => {
      if (popover.isOpen()) {
        popover.close();
      }
    }, 5000);
  }
}
