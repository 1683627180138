import { Subject } from "rxjs";
import { ChangeDetectorRef, Component, inject, OnDestroy } from "@angular/core";

@Component({
  template: ''
})
export abstract class BaseUnsubscribe implements OnDestroy {

  private changeDetectorRef = inject(ChangeDetectorRef);
  protected ngUnsubscribe = new Subject<void>();

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  protected updateUI = () => {
    //force the template to update immediately
    //todo: we'll need a proper fix if we are to remove the change detection strategy ChangeDetectionStrategy.OnPush from the home component
    this.changeDetectorRef.detectChanges();
  }
}

