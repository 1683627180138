import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AdBanner } from "../../core/model/banner/ad-banner";
import * as _ from "lodash";
import { PropertyReportSectionEnum } from "../../core/enum/property-report-section-enum";
import { LoggerService } from "./log/logger.service";

type AdLocation = 'LEFT' | 'RIGHT' ;

@Injectable({
    providedIn: 'root'
})
export class AdBannerRotationService {

    private loggerService: LoggerService = inject(LoggerService);

    private _adBanners = new BehaviorSubject<Map<string, AdBanner>>(new Map<string, AdBanner>());
    adBanners = this._adBanners.asObservable();

    private leftPartialKey: string = '-LEFT-';
    private rightPartialKey: string = '-RIGHT-';
    private rightBottomPartialKey: string = '-RIGHTBOTTOM-';

    setNextLeftSidebarAds = (newAdBanners: AdBanner[]) => {
        let adBannersMap = this._adBanners.getValue();

        let counter: number = 0;
        newAdBanners.forEach(adBanner => {
            let adBannerKey = adBanner.propertyReportSection + this.leftPartialKey + counter;
            adBannersMap.set(adBannerKey, adBanner);
            counter++;
        })

        this._adBanners.next(adBannersMap);
    }

    setNextRightSidebarAds = (newAdBanners: AdBanner[]) => {
        let adBannersMap = this._adBanners.getValue();

        let counter: number = 0;
        newAdBanners.forEach(adBanner => {
            let adBannerKey = adBanner.propertyReportSection + this.rightPartialKey + counter;
            adBannersMap.set(adBannerKey, adBanner);
            counter++;
        })

        this._adBanners.next(adBannersMap);
    }

    setNextRightBottomSidebarAds = (newAdBanners: AdBanner[]) => {

        let adBannersMap = this._adBanners.getValue();



        let counter: number = 0;

        newAdBanners.forEach(adBanner => {

            let adBannerKey = adBanner.propertyReportSection + this.rightBottomPartialKey + counter;

            adBannersMap.set(adBannerKey, adBanner);

            counter++;

        })
     }


    resetPropertyReportSectionAds = (reportSection: PropertyReportSectionEnum, adLocation: AdLocation) => {
        try {
            let reportSectionKeys = Array.from(this._adBanners.getValue().keys()).filter((key) => {
                return key.includes(reportSection + '-' + adLocation + '-');
            });

            reportSectionKeys?.map((key) => {
                return this._adBanners.getValue().delete(key);
            })

            this.loggerService.logDebug(`reset ${reportSection} ${adLocation} side ads`);
        } catch (e) {
            this.loggerService.logError(`error resetting ${reportSection} ${adLocation} side ads`);
        }
    }

    resetAllSidebarAds = () => {
        this._adBanners.next(new Map<string, AdBanner>());
    }

    //TODO:
    //getting an ad banner randomly from the set of ads is not enough, it needs to consider each banner's weight
    getNextAdBanner = (adBannerSet: Set<AdBanner>): AdBanner => {
        let adBanner: AdBanner;

        if (adBannerSet.size > 0) {
            let adBannerIndex: number = _.random(adBannerSet.size -1);
            let adBanners = Array.from(adBannerSet);
            adBanner = adBanners[adBannerIndex];
        }

        return adBanner!;
    }

}
