import { AfterViewInit, Component, effect, ElementRef, EventEmitter, inject, input, OnDestroy, OnInit, Output, output, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatAccordion } from '@angular/material/expansion';
import { UserService } from '../../../shared/service/user.service';
import { PropertyDetail } from '../../../core/model/property/property-detail';
import { PropertyReportService } from '../../../shared/service/property-report.service';
import { UserAccessControl } from '../../../core/model/user/user-access-control';
import { User } from "../../../core/model/user/user";
import { ProductEnum } from '../../../core/enum/product-enum';
import { EstoreService } from '../../../shared/service/estore.service';
import { lastValueFrom, takeUntil } from 'rxjs';
import { ProductCarousel } from '../../../core/model/estore/product-carousel';
import { PropertyReportSearchService } from '../../../shared/service/search/property-report-search.service';
import { MainMapService } from '../main-map/main-map.service';
import { LroPolygonsService } from '../../../shared/service/lro-polygons.service';
import { DataService } from '../../../shared/service/data.service';
import { PropertyDetailSectionEnum } from "../../../core/enum/property-detail-section-enum";
import { PropertyDetailSectionMenuDefinition } from "./menu/property-detail-section-menu-definition";
import { WarningDialogData } from "../../../core/component/modal/warning-dialog/warning-dialog-data";
import { BaseUnsubscribe } from "../../../core/component/base-unsubscribe/base-unsubscribe";
import { SelectedProperty } from "../../../core/model/property/selected-property";
import { LoggerService } from '../../../shared/service/log/logger.service';
import { ScreenManager } from '../../../shared/service/screen-manager.service';
import { ScreenNameEnum } from '../../../core/enum/screen-name.enum';
import { MeasurementUnitService } from "../../../shared/service/measurement-unit.service";
import { UnitOfMeasureType } from "../../../core/enum/unit-of-measure-type";
import { faAnglesDown, faAnglesUp, faCircleArrowLeft, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { GoogleAnalyticsService } from "../../../shared/service/google-analytics.service";
import { SearchComparablesResultService } from '../../../shared/service/search/search-comparables-result.service';
import { WarningService } from "../../../shared/service/warning.service";
import { UrlService } from "../../../shared/service/url.service";
import { EstoreProductCategoryEnum } from "../../../core/enum/estore-product-category-enum";
import { OmnibarStateService } from "../../../shared/service/search/omnibar-state.service";
import { ErrorUtil } from "../../../shared/service/error.util";
import { Router } from '@angular/router';
import { GA_Button, GA_Feature, GA_Modal } from '../../../shared/constant/google-analytics-constants';

@Component({
  selector: 'gema3g-property-report',
  templateUrl: './property-report.component.html',
  styleUrls: ['./property-report.component.scss']
})
export class PropertyReportComponent extends BaseUnsubscribe implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('menuTrigger') trigger: any;
  private warningService = inject(WarningService);
  private urlService = inject(UrlService);
  private omnibarStateService = inject(OmnibarStateService);

  constructor() {
    super();
    this.userAccessControls = this.userService.getUserAccessControl();
    this.user = this.userService.user;

    this.breakpointObserver
    .observe([
      Breakpoints.WebLandscape,
      Breakpoints.WebPortrait
    ])
    .subscribe((state: BreakpointState) => {
      this.breakpointObserver.isMatched(Breakpoints.WebLandscape)
        ? this.webOrientationChange('landscape')
        : this.webOrientationChange('portrait');
    });

    this.widthChange = this.breakpointObserver.observe([
      Breakpoints.Large,
      Breakpoints.XLarge,
    ])
    .subscribe((state: BreakpointState) => {
      this.isLarge = this.breakpointObserver.isMatched(Breakpoints.Large);
      this.isXLarge = this.breakpointObserver.isMatched(Breakpoints.XLarge);
      if (this.isLarge || this.isXLarge) {
        this.trigger.closeMenu()
      }
    })

    effect(() => {
      if (this.propertyReportSectionScrolled()) {
        this.hightlightMenu(this.propertyReportSectionScrolled());
      }
    });
  }

  private elementRef = inject(ElementRef);
  private propertyReportService = inject(PropertyReportService);
  private propertyReportSearchService = inject(PropertyReportSearchService);
  private userService = inject(UserService);
  private estoreService = inject(EstoreService);
  private mainMapService = inject(MainMapService);
  private lroPolygonsService = inject(LroPolygonsService);
  private dataService = inject(DataService);
  private searchComparablesResultService = inject(SearchComparablesResultService);
  private loggerService = inject(LoggerService);
  private screenManager = inject(ScreenManager);
  private measurementUnitService = inject(MeasurementUnitService);
  private gaService = inject(GoogleAnalyticsService);
  private router = inject(Router);
  propertyReportSectionScrolled = input<PropertyDetailSectionEnum>(PropertyDetailSectionEnum.PROPERTY_DETAILS, {alias: 'propertyReportSectionScrolled'});
  public breakpointObserver = inject(BreakpointObserver);

  @ViewChild('accordion') panels: MatAccordion;
  @ViewChild('residentialRelocationPanel', {static: false}) residentialRelocationPanelRef: ElementRef;
  @ViewChild('hoodQPanel', {static: false}) hoodQPanelRef: ElementRef;
  @ViewChild('demographicsPanel', {static: false}) demographicsPanelRef: ElementRef;
  @ViewChild('plansSurveysPanel', {static: false}) plansSurveysPanel: ElementRef;
  @ViewChild('valuationSalesPanel', {static: false}) valuationSalesPanel: ElementRef;
  @ViewChild('siteStructurePanel', {static: false}) siteStructurePanel: ElementRef;
  @ViewChild('propertyDetailsPanel', {static: false}) propertyDetailsPanel: ElementRef;
  condoDetailsPanel: ElementRef;

  @Output() scrollToTop = new EventEmitter();

  userAccessControls: UserAccessControl;
  user: User;
  PE = ProductEnum;
  subjectProperty: PropertyDetail;
  selectedProperty: SelectedProperty = new SelectedProperty();
  loading: boolean;
  summaryPanelDefaultExpanded: boolean = true;
  summaryPanelCurrentExpanded: boolean = this.summaryPanelDefaultExpanded;
  registryPanelDefaultExpanded: boolean = true;
  registryPanelCurrentExpanded: boolean = this.registryPanelDefaultExpanded;
  condoDetailsPanelDefaultExpanded: boolean = true;
  condoDetailsPanelCurrentExpanded: boolean = this.condoDetailsPanelDefaultExpanded;
  siteStructurePanelDefaultExpanded: boolean = true;
  siteStructurePanelCurrentExpanded: boolean = this.siteStructurePanelDefaultExpanded;
  valuationSalesPanelDefaultExpanded: boolean = true;
  valuationSalesPanelCurrentExpanded: boolean = this.valuationSalesPanelDefaultExpanded;
  plansSurveysPanelDefaultExpanded: boolean = true;
  plansSurveysPanelCurrentExpanded: boolean = this.plansSurveysPanelDefaultExpanded;
  residentialRelocationPanelDefaultExpanded: boolean = true;
  residentialRelocationPanelCurrentExpanded: boolean = this.residentialRelocationPanelDefaultExpanded;
  hoodqPanelDefaultExpanded: boolean = true;
  hoodqPanelCurrentExpanded: boolean = this.hoodqPanelDefaultExpanded;
  demographicsPanelDefaultExpanded: boolean = true;
  demographicsPanelCurrentExpanded: boolean = this.demographicsPanelDefaultExpanded;
  carouselProducts: ProductCarousel | undefined;
  reportIsAvailable: boolean = true;
  temporaryUnitOfMeasure: UnitOfMeasureType;
  isPinIncludedInSearchComparablesResult: boolean;
  goBackToolTip: string = 'Back to search comparables result';
  showUnitMeasure = false;
  faAnglesUp = faAnglesUp;
  faAnglesDown = faAnglesDown;
  faCircleArrowLeft = faCircleArrowLeft;
  faCircleXmark = faCircleXmark;
  isLarge: boolean;
  isXLarge: boolean;
  widthChange;
  message: string;
  private sectionToScroll: any;

  closePropertyReport = () => {
    this.screenManager.hideScreen(ScreenNameEnum.PROPERTY_REPORT);
    this.urlService.goToPathWithoutComponentReload('/ui/home', true);  //so that coming back to this screen won't re-open the property report
  }

  getCarouselProductsByBeID = async () => {
    this.propertyReportService.startCarouselProductsLoad();
    this.carouselProducts = await lastValueFrom(this.estoreService.getCarouselProductsByBeID(this.userService.user.businessEntityId));
    this.propertyReportService.endCarouselProductsLoad();
    //console.table(this.carouselProducts);
  }

  getProductCarouselsByPin = async (pin: string) => {
    this.propertyReportService.startCarouselProductsLoad();
    this.carouselProducts = await lastValueFrom(this.estoreService.getCarouselProductsByPin(pin));
    this.propertyReportService.endCarouselProductsLoad();
    //console.table(this.carouselProducts);
  }

  showPropertyReportLoading = () => {
    this.loading = true;

    // var loadingElement = this.elementRef.nativeElement.querySelector('#property-report-loading');
    // if (loadingElement != null) {
    //   loadingElement.scrollIntoView({behavior: "smooth"});
    // }
    // //location.href = "#property-report-loading";
  }

  openUnitMeasure() {
    this.gaService.buttonClicked(GA_Button.TOOLS_BUTTON, GA_Button.TOOLS_BUTTON_LABEL);
    this.showUnitMeasure = !this.showUnitMeasure;
  }

  getPropertyDetail = async (identifier: string) => {
    this.showPropertyReportLoading();
    this.mainMapService.closeAllMarkerBubbles();

    let propertyDetail: PropertyDetail = new PropertyDetail();

    if (this.dataService.isPin(identifier)) {
      propertyDetail = await lastValueFrom(this.propertyReportSearchService.getPropertyDetailByPin(identifier), {defaultValue: new PropertyDetail()});
    } else if (this.dataService.isArn(identifier)) {
      propertyDetail = await lastValueFrom(this.propertyReportSearchService.getPropertyDetailByArn(identifier), {defaultValue: new PropertyDetail()});
    }

    if (propertyDetail) {
      if (!propertyDetail.pii && propertyDetail.amountLeft <= 0) {
        if(propertyDetail.message.startsWith('Product details failed for')){
          this.loggerService.logError(`Product details failed for ${identifier}. Error :: ${propertyDetail.message}`);
          await this.warningService.showWarning(new WarningDialogData(ErrorUtil.PROPERTY_REPORT_NOT_FOUND_HEADER, [ErrorUtil.PROPERTY_REPORT_NOT_FOUND], '', 'Close'), true, 800);
        } else {
          if (!this.user.isRebUser()) {
            await this.showUserReportWarning(propertyDetail.amountLeft, identifier);
          }
        }

        this.reportIsAvailable = false;
        this.screenManager.hideScreen(ScreenNameEnum.PROPERTY_REPORT);
      } else {

        if (!this.user.isRebUser()) {
          this.checkForPropertyCounterWarnings(propertyDetail.amountLeft, identifier);
        }

        this.propertyReportService.updateSubjectProperty(propertyDetail);

        //is this pin part of the search comparables result? if yes, allow this user to go back to the results screen
        this.isPinIncludedInSearchComparablesResult = this.searchComparablesResultService.isPinIncludedInSearchComparablesResult(propertyDetail.pii?.pin);
        this.loggerService.logDebug(`pin ${propertyDetail.pii?.pin} exists in search comparables result snapshot? ${this.isPinIncludedInSearchComparablesResult}`);

        setTimeout(() => {
          this.mainMapService.renderSubjectPropertyMapMarker(propertyDetail);
        }, 200);

        this.lroPolygonsService.setCurrentLro(propertyDetail.pii?.lro);
      }
    }
    this.loading = false;
  }

  checkForPropertyCounterWarnings(amountLeft: number, identifier: string) {
    if ([25, 15, 5, 1].some(value => value === amountLeft)) {
      this.showUserReportWarning(amountLeft, identifier)
        .then(() => {
        this.loggerService.logDebug(`shown UserReportWarning for ${amountLeft}`);
      });
    }
  }

  async showUserReportWarning(counter: number, identifier: string): Promise<void> {
    const content = counter == 0 ? [DataService.PROPERTY_REPORT_NO_BALANCE_WARNING] : [DataService.PROPERTY_REPORT_LOW_BALANCE_WARNING];
    const dialogData = new WarningDialogData('Buy Top-Up?', content, '', 'Remind Me Later', 'Buy Top-up', true);
    dialogData.showOnlyOnceForKey = identifier; // using identifier here as subjectProperty might not yet be available
    this.gaService.openModal(GA_Modal.PROPERTY_REPORT_LOW_BALANCE);
    this.warningService.showWarning(dialogData, true, 600, () => {
      this.urlService.openEstoreCatalogueWithCategory(EstoreProductCategoryEnum.ALL);
    });
  }

  //ToDo 2g is not using this for now
  async showUserReportWarningLong(): Promise<void>{
    const innerHtml = DataService.REPORT_LIMIT_WARNING;
    const dialogData = new WarningDialogData("",[], innerHtml, "Close",);
    this.gaService.openModal(GA_Modal.PROPERTY_REPORT_MAX_COUNTER_REACHED);
    this.warningService.showWarning(dialogData, true, 800);
  }

  menuDefinitions: any[] = [];

  ngOnInit(): void {
    this.showPropertyReportLoading();
    this.userAccessControls = this.userService.getUserAccessControl();

    //listen to new incoming subject property data
    this.propertyReportService.subjectProperty$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newPropertyDetail) => {
        if(newPropertyDetail?.isEmpty){
          return;
        }

        this.loggerService.logInfo(`new property report`, typeof (newPropertyDetail));
        this.temporaryUnitOfMeasure = this.measurementUnitService.isUomUserPreferenceInMeteres ? "METRIC" : "IMPERIAL";

        if (this.screenManager.isScreenVisible(ScreenNameEnum.PROPERTY_REPORT)) {
          this.resetMapPropertyReportDisplay();
        }

        this.showPropertyReportLoading();

        setTimeout(() => {
          if (newPropertyDetail) {
            this.subjectProperty = newPropertyDetail;
            this.selectedProperty.loadFromPropertyDetail(newPropertyDetail, true);
            this.selectedProperty.isSelected = true;
            this.initializeMenu();
            this.loading = false;
            this.getProductCarouselsByPin(this.subjectProperty.pii.pin);
            this.condoDetailsPanel = new ElementRef(document.getElementById('condo-details-container'));

            //Our simplified way to indicate that the property report has finished rendering.
            //TODO: checking the completeness of the property report DOM would be a more accurate check.
            setTimeout(() => {
              this.propertyReportService.setPropertyReportRendered(true);
            }, 200);
          }
        }, 100);
    });

    this.propertyReportService.propertyDetailIdentifier$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((identifier) => {
        setTimeout(() => {
          this.getPropertyDetail(identifier);
        }, 500);
      });

    //open the registry panel when "map it" is requested from site and structure
    this.propertyReportService.siteStructureMapItRequest$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((mapIt) => {
        this.registryPanelCurrentExpanded = true;
      });

    //listen to requests for focusing on property report sections
    this.propertyReportService.propertyReportedPanelScroller$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((panel) => {
        if (panel) {
          this.sectionToScroll = panel;
          setTimeout(() => {
            this.scrollToSectionPanel(panel);
          }, 100);
        } else {
          this.sectionToScroll = null
        }
      });

    this.propertyReportService.propertyPropertyRendered$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((rendered) => {
        if (rendered && this.sectionToScroll) {
              setTimeout(() => {
                this.scrollToSectionPanel(this.sectionToScroll);
              }, 500);
            }
      });

  }

  applyAlternatingePanelBackgroundColor = () => {
    let panels = Array.from(document.getElementsByTagName('mat-expansion-panel') as HTMLCollectionOf<HTMLElement>);
    panels.forEach((panel, index) => {
      if (index % 2 == 0) {
        panel.classList.add('gray_background');
      }
    })
  }

  ngAfterViewInit(): void {
    this.screenManager.closeScreensWhenThisOpened(ScreenNameEnum.PROPERTY_REPORT);
    this.propertyReportService.setStreetViewOpenedFromPropertyReport(false);

    setTimeout(() => {
      this.applyAlternatingePanelBackgroundColor();
    }, 500);
  }

  initializeMenu() {
    this.menuDefinitions = [];
    this.menuDefinitions.push(new PropertyDetailSectionMenuDefinition(PropertyDetailSectionEnum.PROPERTY_DETAILS, true));
    if (this.hasCondoInfo()) {
      this.menuDefinitions.push(new PropertyDetailSectionMenuDefinition(PropertyDetailSectionEnum.CONDO_DETAILS, false));
    }
    if (this.userAccessControls.siteStructureShutter) {
      if(this.userAccessControls.mpacEnhancedDetailsAccess && !this.userAccessControls.MPACSubscriptionEnabled) {
        this.menuDefinitions.push(new PropertyDetailSectionMenuDefinition(PropertyDetailSectionEnum.ENHANCED_SITE_STRUCTURE, false));
      } else {
        this.menuDefinitions.push(new PropertyDetailSectionMenuDefinition(PropertyDetailSectionEnum.SITE_STRUCTURE, false));
      }
    }
    this.menuDefinitions.push(new PropertyDetailSectionMenuDefinition(PropertyDetailSectionEnum.VALUATION_SALES, false));
    this.menuDefinitions.push(new PropertyDetailSectionMenuDefinition(PropertyDetailSectionEnum.PLANS_SURVEYS_EASEMENTS, false));
    if (this.userAccessControls.buyerMobilityPropertyReportShutterAccess) {
      this.menuDefinitions.push(new PropertyDetailSectionMenuDefinition(PropertyDetailSectionEnum.RESIDENTIAL_RELOCATION, false));
    }
    if (this.userAccessControls.hoodqPropertyReportShutterAccess && this.subjectProperty?.pii?.address?.fullAddress) {
      this.menuDefinitions.push(new PropertyDetailSectionMenuDefinition(PropertyDetailSectionEnum.HOODQ, false));
    }
    if (this.userAccessControls.propertyReportDemographicsAccess) {
      this.menuDefinitions.push(new PropertyDetailSectionMenuDefinition(PropertyDetailSectionEnum.DEMOGRAPHICS, false));
    }
  }

  scrollToPanel(panelRef: ElementRef, scrollToCenter: boolean = false, parentPanel: ElementRef | null = null) {
    if (panelRef && panelRef.nativeElement) {
      if (scrollToCenter) {
        panelRef.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: (scrollToCenter) ? 'center' : 'start'
        });
      } else if (parentPanel) {
        const scrollableElement = this.elementRef.nativeElement.parentElement.parentElement;
        scrollableElement.scroll({
          top: panelRef.nativeElement.offsetTop + parentPanel.nativeElement.offsetTop + this.elementRef.nativeElement.firstElementChild.offsetTop - 50,
          behavior: 'smooth'
        });
      } else {
        const scrollableElement = this.elementRef.nativeElement.parentElement.parentElement;
        scrollableElement.scroll({
          top: panelRef.nativeElement.offsetTop + this.elementRef.nativeElement.firstElementChild.offsetTop - 50,
          behavior: 'smooth'
        });
      }
    }
  }

  private scrollToSectionPanel = (section: PropertyDetailSectionEnum) => {
    switch (section) {
      case PropertyDetailSectionEnum.RESIDENTIAL_RELOCATION:
        this.gaService?.buttonClicked(GA_Button.RESIDENTIAL_RELOCATION_BUTTON, GA_Button.RESIDENTIAL_RELOCATION_BUTTON_LABEL);
        if (!this.residentialRelocationPanelCurrentExpanded) this.residentialRelocationPanelCurrentExpanded = true;
        this.scrollToPanel(this.residentialRelocationPanelRef);
        break;
      case PropertyDetailSectionEnum.HOODQ:
        this.gaService?.buttonClicked(GA_Button.HOODQ_BUTTON, GA_Button.HOODQ_BUTTON_LABEL);
        if (!this.hoodqPanelCurrentExpanded) this.hoodqPanelCurrentExpanded = true;
        this.scrollToPanel(this.hoodQPanelRef);
        break;
      case PropertyDetailSectionEnum.DEMOGRAPHICS:
        this.gaService?.buttonClicked(GA_Button.DEMOGRAPHICS_BUTTON, GA_Button.DEMOGRAPHICS_BUTTON_LABEL);
        if (!this.demographicsPanelCurrentExpanded) this.demographicsPanelCurrentExpanded = true;
        this.scrollToPanel(this.demographicsPanelRef);
        break;
      case PropertyDetailSectionEnum.PLANS_SURVEYS_EASEMENTS:
        this.gaService?.buttonClicked(GA_Button.PLANS_SURVEYS_EASEMENTS_BUTTON, GA_Button.PLANS_SURVEYS_EASEMENTS_BUTTON_LABEL);
        if (!this.plansSurveysPanelCurrentExpanded) this.plansSurveysPanelCurrentExpanded = true;
        this.scrollToPanel(this.plansSurveysPanel);
        break;
      case PropertyDetailSectionEnum.VALUATION_SALES:
        this.gaService?.buttonClicked(GA_Button.VALUATION_SALES_BUTTON, GA_Button.VALUATION_SALES_BUTTON_LABEL);
        if (!this.valuationSalesPanelCurrentExpanded) this.valuationSalesPanelCurrentExpanded = true;
        this.scrollToPanel(this.valuationSalesPanel);
        break;
      case PropertyDetailSectionEnum.SITE_STRUCTURE:
        this.gaService?.buttonClicked(GA_Button.SITE_AND_STRUCTURE_BUTTON, GA_Button.SITE_AND_STRUCTURE_BUTTON_LABEL);
        if (!this.siteStructurePanelCurrentExpanded) this.siteStructurePanelCurrentExpanded = true;
        this.scrollToPanel(this.siteStructurePanel);
        break;
      case PropertyDetailSectionEnum.ENHANCED_SITE_STRUCTURE:
        if (!this.siteStructurePanelCurrentExpanded) this.siteStructurePanelCurrentExpanded = true;
        this.scrollToPanel(this.siteStructurePanel);
        break;
      case PropertyDetailSectionEnum.PROPERTY_DETAILS:
        if (!this.registryPanelCurrentExpanded) this.registryPanelCurrentExpanded = true;
        this.scrollToPanel(this.propertyDetailsPanel);
        break;
      case PropertyDetailSectionEnum.PROPERTY_DETAILS_MAPS:
        if (!this.registryPanelCurrentExpanded) this.registryPanelCurrentExpanded = true;
        this.scrollToPanel(this.propertyDetailsPanel, true);
        break;
      case PropertyDetailSectionEnum.CONDO_DETAILS:
        if (!this.registryPanelCurrentExpanded) this.registryPanelCurrentExpanded = true;
        if (!this.condoDetailsPanelCurrentExpanded) this.condoDetailsPanelCurrentExpanded = true;
        this.scrollToPanel(this.condoDetailsPanel, false, this.propertyDetailsPanel);
        break;
      default: break;
    }
  }

  menuClicked(menu: PropertyDetailSectionMenuDefinition) {
    this.scrollToSectionPanel(menu.propertyDetailSection);
  }

  hightlightMenu(propertyReportSection: PropertyDetailSectionEnum) {
    this.menuDefinitions.forEach(value => {
      value.selected = value.propertyDetailSection == propertyReportSection;
    });
  }

  displaySearchComparablesResultSnapshot = () => {
    this.screenManager.hideScreen(ScreenNameEnum.PROPERTY_REPORT);
    this.router.navigateByUrl(UrlService.PATH_TO_SEARCH_COMPARABLES_RESULTS);
  }

  get searchComparablesAccess(): boolean {
    return this.userAccessControls.hasAccessToComparableSearch;
  }

  closePanels = () => {
    setTimeout(() => {
      //this.panels.closeAll(); // this also closes the summary panel which we do not want
      this.summaryPanelCurrentExpanded = true;
      this.registryPanelCurrentExpanded = false;
      this.condoDetailsPanelCurrentExpanded = false;
      this.siteStructurePanelCurrentExpanded = false;
      this.valuationSalesPanelCurrentExpanded = false;
      this.plansSurveysPanelCurrentExpanded = false;
      this.residentialRelocationPanelCurrentExpanded = false;
      this.hoodqPanelCurrentExpanded = false;
      this.demographicsPanelCurrentExpanded = false;
      this.gaService.featureClicked(GA_Feature.PROPERTY_REPORT_COLLAPSE_ALL_SECTIONS, 'PIN', this.propertyReportService.getSubjectPropertyPin());
    }, 100);
  }

  openPanels = () => {
    setTimeout(() => {
      this.panels.openAll();
      this.gaService.featureClicked(GA_Feature.PROPERTY_REPORT_EXPAND_ALL_SECTIONS, 'PIN', this.propertyReportService.getSubjectPropertyPin());
    }, 100);
  }

  goBack() {
    this.scrollToTop.emit();
  }

  resetMapPropertyReportDisplay = () => {
    this.scrollToTop.emit();
  }

  hasCondoInfo(): boolean {
    return !!(this.subjectProperty?.hasCondoDetail());
  }

  selectedUOM($event: any) {
    if ($event) {
      this.temporaryUnitOfMeasure = $event;
      this.measurementUnitService.setUnitOfMeasureUom(this.temporaryUnitOfMeasure);
      this.propertyReportService.newTemporaryChangeOnUom();
    }
  }

  printPDFReports = async () => {
    await this.propertyReportService.printPDFReports();
  }

  webOrientationChange(orientation: string) {
    this.message = orientation;
  }

  onSearchComparables = () => {
    this.omnibarStateService.addSearchComparablesRequest();
  }
}

