import * as _ from 'lodash';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MainMapState } from '../../core/model/spatial/main-map-state';
import { UserService } from '../../shared/service/user.service';
import { User } from '../../core/model/user/user';
import { OmnibarStateService } from '../../shared/service/search/omnibar-state.service';
import { MatDialog } from "@angular/material/dialog";
import { UrlService } from "../../shared/service/url.service";
import { AuthenticationService } from "../../shared/service/authentication.service";
import { SubscriptionExpiredDialogComponent } from "../../core/component/modal/subscription-expired-dialog/subscription-expired-dialog.component";
import { catchError, lastValueFrom, Observable, of, skip, Subscription, switchMap, takeUntil, timer } from "rxjs";
import { BaseUnsubscribe } from "../../core/component/base-unsubscribe/base-unsubscribe";
import { RouteMappingUtility } from "../../shared/utility/route-mapping-utility";
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationSkipped, NavigationStart, Router } from "@angular/router";
import { EstoreProductCategoryEnum } from "../../core/enum/estore-product-category-enum";
import { SearchBusyIndicatorService } from '../../shared/service/search/ui/search-busy-indicator.service';
import { LoggerService } from '../../shared/service/log/logger.service';
import { DataService } from "../../shared/service/data.service";
import { ScreenManager } from '../../shared/service/screen-manager.service';
import { ScreenNameEnum } from '../../core/enum/screen-name.enum';
import { MainMapService } from './main-map/main-map.service';
import { MessageCenterService } from "../../shared/service/message-center.service";
import { BannerMessage } from '../../core/model/message-center/banner-message';
import { GoogleAnalyticsService } from "../../shared/service/google-analytics.service";
import { DialogUtil } from "../../shared/service/dialog.util";
import { WarningDialogData } from "../../core/component/modal/warning-dialog/warning-dialog-data";
import { UserAccessControl } from "../../core/model/user/user-access-control";
import { WarningService } from "../../shared/service/warning.service";
import { WarningTypeEnum } from "../../core/enum/warning-type.enum";
import { HomeService } from "../../shared/service/home.service";
import { HomeParam } from "../../core/model/home/home-param";
import { LocalStorageKey } from "../../shared/constant/constants";
import { PropertyReportService } from '../../shared/service/property-report.service';
import { SearchComparablesResultSnapshot } from '../../core/model/search-result/comparables-result-snapshot';
import { SearchComparablesResultService } from '../../shared/service/search/search-comparables-result.service';
import { MainMapComponent } from './main-map/main-map.component';
import { GA_Modal } from '../../shared/constant/google-analytics-constants';
import { ZendeskService } from '../..//shared/service/zendesk.service';

@Component({
  selector: 'gema3g-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent extends BaseUnsubscribe implements OnInit, AfterViewInit {
  constructor() {
    super();

    this.userAccessControls = this.userService.getUserAccessControl();

    this.route.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        const pin = params['pin'];
        const arn = params['arn'];
        const csr = params['csr'];  //csr (comparable sales result)
        const showMapOnly = params['showMapOnly'];

        if (!this.userAccessControls.restrictedAccess) {
          //the re-display of the property report and the comparables search result page are both handled by this component
          if (pin || arn) {
            this.refreshPropertyReport(new HomeParam(pin, arn, showMapOnly));
          }
        } else {
          //users with restricted access can still access the landing page
          this.router.navigate(['/home']);
        }
      }
    );

    this.homeService.childrenRequest$
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe((params: HomeParam) => {
        this.refreshPropertyReport(params);
      });

    //Subscribe to router events.
    //'runGuardsAndResolvers' in the routing module must be in place in order to handle routing requests to this component when the user is already in this component.
    //Eg: user is requesting "/home?pin=123" when the current route is already at "/home".
    this.navigationSubscription$ = this.router.events
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((routerEvent: any) => {
        //handle the router event accordingly
        if (routerEvent instanceof NavigationStart) {
          this.zendeskService.closeMessagingWidget();

        } else if (routerEvent instanceof NavigationEnd) {
          this.zendeskService.closeMessagingWidget();
          this.checkNavigationForSearchComparables(routerEvent);

        } else if (routerEvent instanceof NavigationSkipped) {
          this.checkNavigationForSearchComparables(routerEvent);

        } else if (routerEvent instanceof NavigationCancel) {
          this.loggerService.logDebug(`navigation to ${routerEvent.url} cancelled`);
          
        } else if (routerEvent instanceof NavigationError) {
        }
    });
  }

  private warningService = inject(WarningService);
  private homeService = inject(HomeService);
  private userService = inject(UserService);
  private omnibarStateService = inject(OmnibarStateService);
  private dialog = inject(MatDialog);
  private authService = inject(AuthenticationService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private searchBusyIndicatorService = inject(SearchBusyIndicatorService);
  private loggerService = inject(LoggerService);
  private mainMapService = inject(MainMapService);
  private dataService = inject(DataService);
  private screenManager = inject(ScreenManager);
  private messageCenterService = inject(MessageCenterService);
  private gaService = inject(GoogleAnalyticsService);
  private propertyReportService = inject(PropertyReportService);
  private searchComparablesResultService = inject(SearchComparablesResultService);
  private urlService = inject(UrlService);
  private zendeskService = inject(ZendeskService);

  @ViewChild(MainMapComponent) mainMapComponent: MainMapComponent;
  navigationSubscription$;
  loggedInUser: User;
  public hoodQMapVisible: boolean = false;
  mainMapState: MainMapState;
  mainMapSearchInProgress: boolean = false;
  isMapControlsMoved = false;
  bannerMessages: BannerMessage | null;
  userAccessControls: UserAccessControl;
  isMobileFullScreen: boolean = false;

  private refreshPropertyReport(params: HomeParam){
    if(params) {
      let clearMapObjectsWhenOpeningPropertyReport: boolean = false;

      if (params.pin && this.dataService.isPin(params.pin) && !params.showMapOnly) {
        this.mainMapService.openPropertyReportByPin(params.pin, clearMapObjectsWhenOpeningPropertyReport);
      } else if (params.arn && this.dataService.isArn(params.arn) && !params.showMapOnly) {
        this.mainMapService.openPropertyReportByArn(params.arn, clearMapObjectsWhenOpeningPropertyReport);
      }
    }
  }

  onHoodQMapOpened = (event: MainMapState) => {
    this.mainMapState = event;
    this.hoodQMapVisible = true;
    this.screenManager.showScreen(ScreenNameEnum.HOODQ);  //keep track of screen visibility
    this.screenManager.closeScreensWhenThisOpened(ScreenNameEnum.HOODQ);
  }

  onHoodQMapClosed = () => {
    this.hoodQMapVisible = false;
    this.screenManager.hideScreen(ScreenNameEnum.HOODQ);  //keep track of screen visibility
  }

  private checkNavigationForSearchComparables = (routerEvent: any) => {
    if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationSkipped) {
      this.onHoodQMapClosed();

      if (routerEvent.url.includes(UrlService.PATH_TO_SEARCH_COMPARABLES_RESULTS)) {
        this.displaySearchComparablesResultWhenMapIsReady();
      } else if (routerEvent.url.includes('/home')) {
        this.mainMapService.clearSearchComparablesObjects();
        this.mainMapService.clearSearchComparableMarkers();

        this.screenManager.hideScreen(ScreenNameEnum.PROPERTY_REPORT);
        this.screenManager.hideScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM);
        this.screenManager.hideScreen(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS);
      }
    }
  }

  ngOnInit() {
    this.userService.getUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        this.loggedInUser = user;
      });

    this.omnibarStateService.omnibarState$
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe((state) => {
        if (state.searchInitiated) {
          this.onHoodQMapClosed();
        }
      });

    this.searchBusyIndicatorService.mainMapBusyFlag$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((busy) => {
        if (busy != null && busy) {
          this.mainMapSearchInProgress = true;
        } else {
          this.mainMapSearchInProgress = false;
        }

        this.updateUI();
      });

    this.screenManager.getObservableScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM)!
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe(visible => {
        if (visible) {
          this.onHoodQMapClosed();
        }
      });

    //get user messages immediately when this component is loaded
    setTimeout(async () => {
      try {
        let bannerMessage = await lastValueFrom(this.messageCenterService.getBannerMessages());
        this.messageCenterService.setBannerMessages(bannerMessage);
      } catch (e) {
        this.loggerService.logError(e)
      }
    }, 500);

    //then get user messages every 1 minute
    setTimeout(() => {
      try {
        const intervalPeriod: number = 1;
        const minutes: number = intervalPeriod * 60 * 1000;
        let bannerMessagesSubscription: Subscription = timer(0, minutes)
          .pipe(skip(1), takeUntil(this.ngUnsubscribe))
          .pipe(
            switchMap(() => {
              return this.messageCenterService.getBannerMessages()
                .pipe(catchError(err => {
                  this.loggerService.logWarning(`error getting banner messages for user beid ${this.userService.getBEID()}`);
                  return of(null);
                }));
            })
          )
          .subscribe(bannerMessages => {
            this.messageCenterService.setBannerMessages(bannerMessages);
          });
      } catch (e) {
        this.loggerService.logError(e);
      }
    }, 500);

    try {
      const intervalPeriod: number = 1;
      const minutes: number = intervalPeriod * 60 * 1000;
      let expiredBannerMessagesSubscription: Subscription = timer(0, minutes)
        .pipe(skip(1), takeUntil(this.ngUnsubscribe))
        .pipe(
          switchMap(() => {
            return this.messageCenterService.getExpiredBannerMessages()
              .pipe(catchError(err => {
                this.loggerService.logWarning(`error getting expired banner messages for user beid ${this.userService.getBEID()}`);
                return of(null);
              }));
          })
        )
        .subscribe(bannerMessages => {
          this.messageCenterService.setExpiredBannerMessages(bannerMessages);
        });
    } catch (e) {
      this.loggerService.logError(e);
    }

    this.messageCenterService.bannerMessages$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((bannerMessages) => {
      this.bannerMessages = bannerMessages;
      this.updateUI();
    });

    //listen to new incoming subject property identifier (pin or arn) and open the property report with the pin or arn request parameters visible (handled by this component's route queryParams observable)
    this.propertyReportService.subjectPropertyIdentifier$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((identifier) => {
        if (!_.isEmpty(identifier) && (this.dataService.isPin(identifier) || this.dataService.isArn(identifier))) {
          setTimeout(async () => {
            try {
              this.mainMapService.exitFullScreenMap();
            } catch (e) {
            }

            if (this.dataService.isPin(identifier)) {
              await this.router.navigate(['/home'], {
                queryParams: {pin: identifier}
              });
            } else if (this.dataService.isArn(identifier)) {
              await this.router.navigate(['/home'], {
                queryParams: {arn: identifier}
              });
            }

            this.screenManager.closeScreensWhenThisOpened(ScreenNameEnum.PROPERTY_REPORT);
            this.screenManager.showScreen(ScreenNameEnum.PROPERTY_REPORT);

            if (this.propertyReportService.getCurrentPropertyDetailIdentifier() != identifier) {
              this.propertyReportService.setNextPropertyDetailIdentifier(identifier);
            }
          }, 500);
        }
      });

    this.checkUserSessionValidity();
  }

  ngAfterViewInit(): void {
    this.showLicenseExpiringDialogIfNeeded();
  }

  private showLicenseExpiringDialogIfNeeded() {
    if (this.loggedInUser.doesUserNeedLicenseReminder() && !this.userAccessControls.GWHRSubscriptionExpired) {
      const content = this.loggedInUser.isMultiLicense ? [DataService.LICENSE_SUBSCRIPTION_RENEWAL_CONTENT_ML] : [DataService.LICENSE_SUBSCRIPTION_RENEWAL_CONTENT_SL];
      const dialogData = new WarningDialogData(DataService.LICENSE_SUBSCRIPTION_RENEWAL_HEADER, content, '', 'Remind Me Later', 'Renew Now', !this.loggedInUser.isMultiLicense);
      this.warningService.showWarningOnceADay(WarningTypeEnum.LICENSE_RENEWAL, dialogData, false, 560, () => {
        this.router.navigate(['../catalogue', RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION)]);
      });
    }
  }

  async checkUserSessionValidity() {
    await this.userService.reloadUserAccessControlsIfNeeded();
    if ( this.userAccessControls.GWHRSubscriptionExpired){
      this.displayUserLicenseIsExpiredDialog();
    } else if (! this.userAccessControls.userActive){
      this.displayUserIsSuspendedDialog();
    }
  }

  private displayUserLicenseIsExpiredDialog() {
    this.gaService.openModal('SubscriptionExpiredDialog');
    const dialogRef = this.dialog.open(SubscriptionExpiredDialogComponent, {disableClose: true, maxWidth: 500})
      .afterClosed()
      .subscribe(async (resp) => {
          if (resp) {
            if (resp.openCatalogue) {
              this.router.navigate(['../catalogue', RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION)]);
            } else {
              this.loggerService.logDebug('logging out');
              this.logout();
            }
          } else {
            this.loggerService.logDebug("no user response from modal"); // it should not happen
          }
        }
      );
  }

  private displayUserIsSuspendedDialog(): void {
    this.gaService.openModal(GA_Modal.ACCOUNT_SUSPENDED);
    const dialogData = new WarningDialogData(DialogUtil.ACCOUNT_IS_SUSPENDED_TITLE, [DialogUtil.ACCOUNT_IS_SUSPENDED_MESSAGE], '','Log Out');
    this.warningService.showWarning(dialogData, true, undefined, undefined, () =>{
      setTimeout(() => {
        this.logout();
      }, 100);
    });
  }


  private displaySearchComparablesResult = (display: boolean) => {
    if (!display) {
      this.loggerService.logDebug(`search results payload snapshot will not be displayed`);
      return;
    }

    let snapshot: SearchComparablesResultSnapshot | null = this.searchComparablesResultService.getSearchResultsSnapshotFromStorage();

    if (snapshot) {
      this.loggerService.logDebug(`search results payload snapshot found`);

      setTimeout(() => {
        this.loggerService.logDebug(`search results payload snapshot new? ${snapshot.new}`);

        //set the screen orientation and screen display size before displaying the search results
        this.screenManager.setDefaultSearchComparablesScreenOrientation(snapshot.screenOrientation);
        this.screenManager.setDefaultSearchComparablesScreenDisplay(snapshot.screenDisplay);

        this.screenManager.showScreen(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS);
        this.screenManager.closeScreensWhenThisOpened(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS);

        snapshot.render = true;
        this.searchComparablesResultService.updateSearchResultsSnapshot(snapshot);
      }, 200);
    } else {
      this.screenManager.hideScreen(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS);
      this.loggerService.logDebug(`no search results payload snapshot found`);
    }
  }

  async logout() {
    const loggedOut = await lastValueFrom(this.authService.logoutWithoutRedirect());
    if(!loggedOut) {
      document.location.href = UrlService.REDIRECT_AFTER_SESSION_TIMEOUT;
    }
  }

  moveMapControls(val:boolean) {
    this.isMapControlsMoved = val;
    this.loggerService.logDebug('isMapControlsMove', this.isMapControlsMoved)
  }

  mobileFullScreenMode(result: boolean) {
    this.isMobileFullScreen = result;
  }

  /**
   * This method is called when the user navigates to the search comparables results page via the url path UrlService.PATH_TO_SEARCH_COMPARABLES_RESULTS.
   * The results page interacts with the map and therefore the map needs to be in a ready state before the results page is displayed.
   * We cannot rely on the maps's idle event to determine the map's readiness since the path UrlService.PATH_TO_SEARCH_COMPARABLES_RESULTS is not an actual configured route
   * and therefore will not reload this component. I.e., it will not trigger any activity on this component that would otherwise trigger the child map's idle event.
   */
  private displaySearchComparablesResultWhenMapIsReady = async () => {
    let conditionMet: boolean = false;
    let attempts: number = 0;

    let me = this;
    while (!conditionMet) {
      attempts++;

      conditionMet = await me.checkMainMapState() as boolean;

      if (conditionMet) {
        this.loggerService.logDebug(`main map is ready for search comparables results`);
        this.displaySearchComparablesResult(true);
      } else {
        this.loggerService.logDebug(`search comparables results waiting for map to be ready... ${attempts}`);
      }
    }
  }

  private checkMainMapState = () => {
    return new Promise((resolve) => {
      const mapComponentInstantiated = this.mainMapComponent != undefined;
      const mapHasIdled = this.mainMapService?.getMap()?.data.get('isMapIdled');
      const mapIsReady = mapComponentInstantiated && mapHasIdled;

      setTimeout(() => {
        resolve(mapIsReady);
      }, 100);
    });
  }
}
