import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "./google-analytics.service";
import { LoggerService } from "./log/logger.service";
import { environment } from "../../../environments/environment";
import { RouteMappingUtility } from "../utility/route-mapping-utility";
import { EstoreProductCategoryEnum } from "../../core/enum/estore-product-category-enum";
import { GA_Navigate } from "../constant/google-analytics-constants";

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor() {
  }

  private loggerService = inject(LoggerService);
  private router = inject(Router);
  private gaService = inject(GoogleAnalyticsService);

  public static REDIRECT_AFTER_SESSION_TIMEOUT: string = environment.url.MARKETING_SITE;
  public static REDIRECT_TO_INSIGHTER: string = environment.url.BECOME_INSIGHTER_URL;
  public static REDIRECT_TO_HELP_AND_SUPPORT = environment.url.HELP_AND_SUPPORT_URL;
  public static REDIRECT_To_CONTACT_US = environment.url.CONTACT_US_URL;
  public static REDIRECT_To_OVERVIEW = environment.url.OVERVIEW_URL;
  public static REDIRECT_TO_HOW_TO_LIBRARY = environment.url.HOW_TO_LIBRARY_URL;
  public static REDIRECT_TO_TRAINING_CALENDAR = environment.url.TRAINING_CALENDAR_URL;
  public static REDIRECT_TO_ZENDESK: string = environment.url.ZENDESK;

  public static PATH_TO_SEARCH_COMPARABLES_RESULTS: string = '/home?csr=1';

  // these should be urls that are not changing between environments
  public static DEFAULT_ZENDESK_URL: string = "https://teranetcommercialsolutions.zendesk.com/";
  public static ZENDESK_REQUEST_URL: string = 'https://teranetcommercialsolutions.zendesk.com/api/v2/requests';
  public static PROPERTY_LINE_URL: string = "https://www.propertyline.ca";
  public static SECURITY_STATEMENT_URL: string = "https://www.teranet.ca/legal-notice/";

  goToLoginPage = async () => {
    this.gaService.navigate(GA_Navigate.TO_LOGIN);
    this.loggerService.logInfo('UrlService -> redirect to /login');
    await this.router.navigate(['/login']).then(() => {
    });
  }

  goToAcceptTermsAndConditionsPage = async () => {
    this.gaService.navigate(GA_Navigate.TO_LEGAL_AGREEMENT);
    await this.router.navigate(['/legal-agreement']).then(() => {
    });
  }

  goToInitializeUserProfilePage = async () => {
    this.gaService.navigate(GA_Navigate.TO_INITIALIZE_PROFILE);
    await this.router.navigate(['/initialize-profile']).then(() => {
    });
  }

  goToLandingPage = async () => {
    this.gaService.navigate(GA_Navigate.TO_HOME);
    await this.router.navigate(['/home']).then(() => {
    });
  }

  goToEstorePage = async () => {
    this.gaService.navigate(GA_Navigate.TO_ESTORE);
    await this.router.navigate(['/estore']).then(() => {
    });
  }

  goToCataloguePage = async () => {
    this.gaService.navigate(GA_Navigate.TO_ESTORE_CATALOGUE);
    await this.router.navigate(['/catalogue']).then(() => {
    });
  }

  openEstoreCatalogueWithCategory(productCategory: EstoreProductCategoryEnum) {
    this.router.navigate(['../catalogue', RouteMappingUtility.eStoreCategoryToRoute(productCategory)]);
  }

  goToMarketingSitePage = async () => {
    this.gaService.navigate(GA_Navigate.TO_MARKETING_SITE);
    document.location.href = environment.url.MARKETING_SITE;
  }

  goToPathWithoutComponentReload = (path: string, keepHistory: boolean) => {  //note: path here may not be a real configured angular route
    if (keepHistory) {
      window.history.pushState({}, '', path);
    } else {
      window.history.replaceState({}, '', path);
    }
  }

}
