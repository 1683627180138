import { inject, Injectable } from "@angular/core";
import { LoggerService } from "./log/logger.service";
import { environment } from "../../../environments/environment";
import { UserService } from "./user.service";
import { GA_Event } from "../constant/google-analytics-constants";

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() {
    if (environment.isGoogleAnalyticsEnabled) {
      this.loggerService.logInfo('Google Analytics is enabled. Events will be sent to Google Analytics');
    } else {
      this.loggerService.logInfo('Google Analytics is disabled. No events will be sent to Google Analytics');
    }
  }

  private loggerService: LoggerService = inject(LoggerService);
  private userService = inject(UserService);

  // ToDo investigate if this event can be tracked easier from app.component with something like this.router.events....
  public navigate(category: string, label?: string, value: string = '') {
    this.sendEvent(GA_Event.NAVIGATE, category, label, value);
  }

  public openModal(category: string, label?: string, value: string = '') {
    this.sendEvent(GA_Event.OPEN_MODAL, category, label, value);
  }

  public openPage(category: string, label?: string, value: string = '') {
    this.sendEvent(GA_Event.OPEN_PAGE, category, label, value);
  }

  public openSection(category: string, label?: string, value: string = '') {
    this.sendEvent(GA_Event.OPEN_SECTION, category, label, value);
  }

  public buttonClicked(category: string, label?: string, value: string = '') {
    this.sendEvent(GA_Event.BUTTON_CLICKED, category, label, value);
  }

  public featureClicked(category: string, label?: string, value: string = '') {
    this.sendEvent(GA_Event.FEATURE_CLICKED, category, label, value);
  }

  /**
   * A parameter needs to be configured as a Custom Dimension in GA in order to make that parameter visibile in the GA UI 
   * as well as make it available for reporting purposes beyond the 30-min realtime event capturing window.
   */
  public sendEvent(action: string, category: string, label?: string, value: string = '') {
    if (environment.isGoogleAnalyticsEnabled) {
      try {
        gtag('event', action, {
          'event_category': category,
          'event_label': label,
          'value': value,
          'gema3g': [category, label, value, this.userService.user.businessEntityId].join('-')
        });
      } catch (ex) {
        this.loggerService.logError("Could not send event to Google Analytics", ex);
      }
    }
  }

  public sendABEvent(action: string, value: string = '') {
    if (environment.isABTestingEnabled) {
      try {
        gtag('event', GA_Event.BUSINESS_ENTITY_ID, {
          'action': [action, value].join('-')
        });
      } catch (ex) {
        this.loggerService.logError("Could not send A/B event to Google Analytics", ex);
      }
    }
  }
}
