import { inject, Injectable } from "@angular/core";
import { AdBannerAction } from "../../core/model/banner/ad-banner-action";
import { AdBannerActionEnum } from "../../core/enum/ad-banner-action-enum";
import { SSOOpenIDConnectJson } from "../../core/model/sso/sso-oidc-json";
import { lastValueFrom } from "rxjs";
import { SSOService } from "./sso.service";
import { PropertyReportService } from "./property-report.service";
import { Router } from "@angular/router";
import { EstoreService } from "./estore.service";
import { LoggerService } from "./log/logger.service";
import { EStoreParam } from "../../core/model/product/e-store/e-store-param";

@Injectable({
  providedIn: 'root'
})
export class AdBannerService {

  constructor() {
  }

  private ssoService = inject(SSOService);
  private propertyReportService = inject(PropertyReportService);
  private router = inject(Router);
  private eStoreService = inject(EstoreService);
  private loggerService = inject(LoggerService);

  public invokeAction(adBannerAction: AdBannerAction) {

    if (adBannerAction) {
      switch (adBannerAction.actionType) {
        case AdBannerActionEnum.NAVIGATE_TO_ROUTE:
          this.router.navigate(adBannerAction.navigateTo);
          break;
        case AdBannerActionEnum.OPEN_URL:
          //ToDo
          break;
        case AdBannerActionEnum.OPEN_ZOOM2_IT:
          this.openZoom2It();
          break;
        case AdBannerActionEnum.OPEN_PARCEL_REGISTER_REPORT:
          this.openParcelRegisterReport();
          break;
         case AdBannerActionEnum.OPEN_TITLE_CHECK_PARCEL_REGISTER_REPORT:
          this.openTitleCheckParcelRegisterReport();
          break;
        case AdBannerActionEnum.OPEN_CONDUIT:
          this.openConduitWindow();
          break;
        default:
          this.loggerService.logInfo(`${adBannerAction.actionType} does not have an assigned handler`);
      }
    } else {
      this.loggerService.logInfo('received a null banner action');
    }
  }

  async openZoom2It() {
    const ssoOIDCJson: SSOOpenIDConnectJson = await lastValueFrom(this.ssoService.getOpenIDConnectJson(), {defaultValue: new SSOOpenIDConnectJson()});

    if (ssoOIDCJson?.accessToken) {
      let url = ssoOIDCJson.accessTokenLoginUrl;
      let propertyDetail = this.propertyReportService.getSubjectProperty();

      let pin: string = '999999999';
      let isPurchase: boolean = false;
      let lro: string = '99';

      if (propertyDetail?.pii?.pin) {
        pin = propertyDetail?.pii?.pin;
        isPurchase = true;
        lro = propertyDetail?.pii?.lro;
      }
      this.openZoom2ItWindow( url + "&resource=" + encodeURIComponent(`/zoom2itplus.jsp?pinvalue=${pin}&lrovalue=${lro}&purchasevalue=${isPurchase}`));

    }
  }

  openZoom2ItWindow(url: string) {
    try {
      let zoom2ItWindow = window.open(url, '_blank');
      if (zoom2ItWindow) {
        zoom2ItWindow.focus();

        zoom2ItWindow.addEventListener("load", () => {
          zoom2ItWindow.document.title = 'Zoom2it Aerial Imagery';
        });
      }

    } catch (e) {
      this.loggerService.logError(`error opening zoom2it endpoint`, e);
    }
  }

  async openParcelRegisterReport() {
    let propertyDetail = this.propertyReportService.getSubjectProperty();
    const eStoreParam = new EStoreParam('ParcelRegister', propertyDetail?.pii?.pin,'', propertyDetail?.pii?.lro);
    eStoreParam.landRegistryStatus = propertyDetail?.pii?.landRegistryStatus;
    await this.eStoreService.openReport(eStoreParam);
  }
  async openTitleCheckParcelRegisterReport() {
          let propertyDetail = this.propertyReportService.getSubjectProperty();
          const eStoreParam = new EStoreParam('ParcelRegisterWithTitleCheck', propertyDetail?.pii?.pin,'', propertyDetail?.pii?.lro);
          eStoreParam.landRegistryStatus = propertyDetail?.pii?.landRegistryStatus;
          await this.eStoreService.openReport(eStoreParam);
  }
  async openConduitWindow() {
    try {
      let width = 1000;
      let height = 600;
      let left = (screen.width - width) / 2;
      let top = (screen.height - height) / 4;
      let attributes = 'resizable=no,scrollbars=yes,status=no,toolbar=no,menubar=no,location=no,titlebar=0,width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
      
      let pin = this.propertyReportService.getSubjectPropertyPin();
      let conduit = await lastValueFrom(this.eStoreService.getConduitUrl(pin), {defaultValue: []});
      var conduitPopup = window.open(decodeURIComponent(conduit[0]), "Conduit", attributes);
    } catch (e) {
      this.loggerService.logError(`error opening conduit endpoint`, e);
    }

  }

}
