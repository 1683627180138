@if (userAccessControls.propertyReportAccess && reportIsAvailable) {
  <div #propertyReportContainer class="property-report" id="property-report-container">
    <header class="pr-sticky-header pr-2 xl:px-4 2xl:px-8">
      <div class="pr-header-container no-print">
        <div>
          <!--large menu-->
          <div class="hidden lg:block">
            <mat-nav-list id="pr-nav-list-lm-menu" [style.pointer-events]="userAccessControls?.restrictedAccessPointerEvents" class="geo-mat-menu-horizontal" [disableRipple]="true">
              @if (isPinIncludedInSearchComparablesResult) {
                <mat-list-item id="pr-list-item-lm-comps-results-back" class="menu-item menu-item__left">
                  <span class="menu-title">
                    <span id="pr-icon-lm-comps-results-back" (click)="displaySearchComparablesResultSnapshot()" [satPopoverAnchor]="goBackPopover" (mouseover)="goBackPopover.open()" (mouseout)="goBackPopover.close()">
                      <fa-icon class="fa-lg back-results" [icon]="faCircleArrowLeft"></fa-icon>
                    </span>
                    <sat-popover #goBackPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                      <gema3g-tooltip [content]="goBackToolTip"></gema3g-tooltip>
                    </sat-popover>
                  </span>
                </mat-list-item>
              }
              @defer (on timer(3s)) {
              @for (menu of menuDefinitions; track $index) {
                <mat-list-item id="pr-list-item-lm-{{menu.title}}" (click)="menuClicked(menu)" [ngClass]="{'menu-selected-item' : menu.selected}"
                  class="menu-item menu-item__left">
                  <span class="menu-title">
                    <span id="pr-lm-menu-title-{{menu.title}}" class="text-xxs lg:text-xs 2xl:text-sm" [innerHtml]="menu.title"></span>
                  </span>
                </mat-list-item>
              }
              <mat-list-item>
                <div class="flex flex-row gap-1">
                  <span id="pr-panels-close" (click)="closePanels()" title="Collapse all sections"><fa-icon class="fa-sm" [icon]="faAnglesUp"></fa-icon></span>
                  <span id="pr-panels-open" (click)="openPanels()" title="Expand all sections"><fa-icon class="fa-sm" [icon]="faAnglesDown"></fa-icon></span>
                </div>
              </mat-list-item>
            }
          </mat-nav-list>
        </div>
        <!--small menu-->
        @defer (on timer(3s)) {
        <div class="lg:hidden">
          <button id="pr-btn-sm-menu" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="smallMenu" mat-menu-item>
            <mat-icon id="pr-icon-sm-menu">menu</mat-icon>
          </button>
          <mat-menu id="pr-menu-sm" #smallMenu="matMenu" yPosition="below">
            @for (menu of menuDefinitions; track $index) {
              <button id="pr-btn-sm-menu-title-{{menu.title}}" (click)="trigger.closeMenu();menuClicked(menu)" mat-menu-item class="md:hidden">
                <span id="pr-sm-menu-title-{{menu.title}}" [innerHtml]="menu.title"></span>
              </button>
            }
          </mat-menu>
        </div>
      }
    </div>
    <div>
      <!--Print/Tools/etc-->
      <div class="flex flex-row items-center text-white relative">
        <div class="menu-item menu-item__right" (click)="printPDFReports()">
          <img id="pr-img-print" src="assets/img/svg/GWHPrint.svg" class="h-4 pr-right-menu-img"/>
          <button id="pr-btn-print" mat-button class="white-text-button">
            <span id="pr-print" class="text-xxs lg:text-xs 2xl:text-sm">Print PDF</span>
          </button>
        </div>
        @if (searchComparablesAccess) {
          <div class="menu-item menu-item__right" (click)="onSearchComparables()">
            <img id="pr-img-scp" src="assets/img/icon_compare_property.png" class="h-4 pr-right-menu-img"/>
            <button id="pr-btn-scp" mat-button class="white-text-button">
              <span id="pr-scp" class="text-xxs lg:text-xs 2xl:text-sm">Comparables</span>
            </button>
          </div>
        }
        <div class="menu-item menu-item__right" (click)="openUnitMeasure()">
          <img id="pr-img-tools" src="assets/img/svg/GWHPageTools.svg" class="h-4 pr-right-menu-img"/>
          <button id="pr-btn-tools" mat-button class="white-text-button">
            <span id="pr-tools" class="text-xxs lg:text-xs 2xl:text-sm">Tools</span>
          </button>
        </div>
        @if (showUnitMeasure) {
          <div class="absolute right-20 top-[50px] p-[15px] bg-black">
            <div class="flex flex-row gap-2 mb-5">
              <div class="h-3 min-w-[30px]"><img id="pr-img-uom" src="assets/img/svg/GWHUnitofMeasure.svg"></div>
              <div id="pr-uom" class="text-center">Unit of Measure</div>
            </div>
            <div class="flex flex-col gap-2 text-white">
              <div class="text-center">
                <mat-button-toggle-group id="pr-button-toggle-group-uom" [(ngModel)]="temporaryUnitOfMeasure" (valueChange)="selectedUOM($event)">
                  <mat-button-toggle id="pr-button-toggle-uom-m" class="button-toggle text-blue-50" value="METRIC">Metric</mat-button-toggle>
                  <mat-button-toggle id="pr-button-toggle-uom-i" class="button-toggle text-blue-50" value="IMPERIAL">Imperial</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </div>
        }
        <span id="pr-btn-close" class="flex flex-row items-center" (click)="closePropertyReport()">
          <fa-icon class="fa-lg text-white" [icon]="faCircleXmark"></fa-icon>
        </span>
      </div>
    </div>
  </div>
</header>
<div class="body" id="property-report-body">
  @if (loading) {
    <div class="flex flex-row justify-center items-start pt-10 min-h-screen bg-white text-lg" id="property-report-loading">
      <img id="pr-img-refresh" src="assets/img/icon-refresh.gif">
    </div>
  }
  @if (!loading) {
    <div class="details">
      <div>
        <mat-accordion id="pr-accordion" #accordion="matAccordion" multi>
          <mat-expansion-panel id="pr-expansion-panel-summary" [(expanded)]="summaryPanelDefaultExpanded">
            <!--
            Summary section cannot have the appearance of a panel.
            However we still need it to be a panel for content alignment with the rest of the sections.
            Summary has a hidden header.
            <mat-expansion-panel-header>
              <mat-panel-title>
                Summary
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            -->
            <gema3g-property-report-summary [userAccessControls]="userAccessControls"></gema3g-property-report-summary>
          </mat-expansion-panel>
          <div #propertyDetailsPanel id="registry-panel">
            <div class="page-break-on-print"></div>
            <mat-expansion-panel id="pr-expansion-panel-registry" [(expanded)]="registryPanelCurrentExpanded">
              <mat-expansion-panel-header id="pr-expansion-panel-header-registry">
                <mat-panel-title id="pr-panel-title-registry">
                  <h2 id="pr-h2-registry" class="pr-panel-header-title">Property Details</h2>
                </mat-panel-title>
                <mat-panel-description id="pr-panel-description-registry">
                </mat-panel-description>
              </mat-expansion-panel-header>
              <gema3g-property-report-registry
                [carouselProducts]="carouselProducts?.prop_details"
                [selectedProperty]="selectedProperty"
                [userAccessControls]="userAccessControls"
                >
              </gema3g-property-report-registry>
            </mat-expansion-panel>
          </div>
          <div #siteStructurePanel id="siteStructurePanel">
            @if (userAccessControls.siteStructureShutter) {
              <div class="page-break-on-print"></div>
              <mat-expansion-panel id="pr-expansion-panel-ss" [(expanded)]="siteStructurePanelCurrentExpanded">
                <mat-expansion-panel-header id="pr-expansion-panel-header-ss">
                  <mat-panel-title id="pr-panel-title-ss">
                    @if (!userAccessControls.mpacEnhancedDetailsAccess) {
                      <h2 id="pr-h2-ss" class="pr-panel-header-title">Site & Structure</h2>
                    }
                    @if (userAccessControls.mpacEnhancedDetailsAccess) {
                      <h2 id="pr-h2-ess" class="pr-panel-header-title">Enhanced Site & Structure</h2>
                    }
                  </mat-panel-title>
                  <mat-panel-description id="pr-panel-description-ss">
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <gema3g-property-report-site-structure [enhancedSiteStructureCarouselProducts]="carouselProducts?.enhanced_site_structure" [siteStructureCarouselProducts]="carouselProducts?.site_structure"
                [userAccessControls]="userAccessControls"></gema3g-property-report-site-structure>
              </mat-expansion-panel>
            }
          </div>
          <div class="page-break-on-print"></div>
          <div #valuationSalesPanel id="valuation-sales-panel">
            <mat-expansion-panel id="pr-expansion-panel-vs" [(expanded)]="valuationSalesPanelCurrentExpanded">
              <mat-expansion-panel-header id="pr-expansion-panel-header-vs">
                <mat-panel-title id="pr-panel-title-vs">
                  <h2 id="pr-h2-vs" class="pr-panel-header-title">Valuation & Sales</h2>
                </mat-panel-title>
                <mat-panel-description id="pr-panel-description-vs">
                </mat-panel-description>
              </mat-expansion-panel-header>
              <gema3g-property-report-valuation-sales
                [carouselProducts]="carouselProducts?.valuation_sales"
                [selectedProperty]="selectedProperty"
                [userAccessControls]="userAccessControls"
                >
              </gema3g-property-report-valuation-sales>
            </mat-expansion-panel>
          </div>
          @if (carouselProducts) {
            <div #plansSurveysPanel id="pr-expansion-div-panel-ps">
              <div class="page-break-on-print"></div>
              <mat-expansion-panel id="pr-expansion-panel-ps" [(expanded)]="plansSurveysPanelCurrentExpanded">
                <mat-expansion-panel-header id="pr-expansion-panel-header-ps">
                  <mat-panel-title id="pr-panel-title-ps">
                    <h2 id="pr-h2-ps" class="mb-7 pr-panel-header-title">Plans, Surveys & Easements</h2>
                  </mat-panel-title>
                  <mat-panel-description id="pr-panel-description-ps">
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <gema3g-property-report-plans-surveys
                  [easementCarouselProducts]="carouselProducts.plans_surveys_easements"
                  [planSurveyCarouselProducts]="carouselProducts.plans_surveys"
                  [selectedProperty]="selectedProperty"
                  [userAccessControls]="userAccessControls">
                </gema3g-property-report-plans-surveys>
              </mat-expansion-panel>
            </div>
          }
          @if (carouselProducts && userAccessControls.buyerMobilityPropertyReportShutterAccess) {
            <div #residentialRelocationPanel id="residential-relocation-panel" class="no-print">
              <mat-expansion-panel id="pr-expansion-panel-rr" [(expanded)]="residentialRelocationPanelCurrentExpanded">
                <mat-expansion-panel-header id="pr-expansion-panel-header-rr">
                  <mat-panel-title id="pr-panel-title-rr">
                    <h2 id="pr-h2-rr" class="pr-panel-header-title">Residential Relocation</h2>
                  </mat-panel-title>
                  <mat-panel-description id="pr-panel-description-rr">
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <gema3g-property-report-residential-relocation
                  [carouselProducts]="carouselProducts.buyer_mobility"
                  [selectedProperty]="selectedProperty"
                  [userAccessControls]="userAccessControls">
                </gema3g-property-report-residential-relocation>
              </mat-expansion-panel>
            </div>
          }
          <div #hoodQPanel id="hoodq-panel" class="no-print">
            @if (userAccessControls.hoodqPropertyReportShutterAccess && subjectProperty?.pii?.address?.fullAddress) {
              <mat-expansion-panel id="pr-expansion-panel-hoodq" [(expanded)]="hoodqPanelCurrentExpanded">
                <mat-expansion-panel-header id="pr-expansion-panel-header-hoodq">
                  <mat-panel-title id="pr-panel-title-hoodq">
                    <h2 id="pr-h2-hoodq" class="pr-panel-header-title">HoodQ™</h2>
                  </mat-panel-title>
                  <mat-panel-description id="pr-panel-description-hoodq">
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <gema3g-property-report-hoodq [carouselProducts]="carouselProducts?.hoodq"
                  [selectedProperty]="selectedProperty"
                  [userAccessControls]="userAccessControls"
                  >
                </gema3g-property-report-hoodq>
              </mat-expansion-panel>
            }
          </div>
          <div #demographicsPanel id="demographics-panel">
            @if (userAccessControls.propertyReportDemographicsAccess) {
              <div class="page-break-on-print"></div>
              <mat-expansion-panel id="pr-expansion-panel-dg" [(expanded)]="demographicsPanelCurrentExpanded">
                <mat-expansion-panel-header id="pr-expansion-panel-header-dg">
                  <mat-panel-title id="pr-panel-title-dg">
                    <h2 id="pr-h2-dg" class="pr-panel-header-title">Demographics</h2>
                  </mat-panel-title>
                  <mat-panel-description id="pr-panel-description-dg">
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <gema3g-property-report-demographics></gema3g-property-report-demographics>
              </mat-expansion-panel>
            }
          </div>
        </mat-accordion>
      </div>
    </div>
  }
</div>
</div>
}
