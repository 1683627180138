import {BaseModel} from "../../../base-model";
import {PropertyDetail} from "../../../property/property-detail";
import {PdfReportCircleRequest} from "../../pdf-report-circle-request";
import {UserPreference} from "../../../user/preference/user-preference";
import {UserAccessControl} from "../../../user/user-access-control";
import {ComparableSalesResultPayload} from "../../../comparables/comparable-sales-result-payload";
import dayjs from "dayjs";
import {comparablesLotSizeValues, lotSizeValues} from "../../../../../shared/constant/constants";
import { DataService } from "../../../../../shared/service/data.service";

export class PdfReportAreaSaleConfiguration extends BaseModel {

  constructor(propertyDetail: PropertyDetail, userPreference: UserPreference | undefined, userAccessControls: UserAccessControl, comparableSales?: ComparableSalesResultPayload) {
    super();
    if (propertyDetail && !comparableSales) {
      // if modal not opened from comparable sales request, load circle request from user preferences
      this.defaultMaxDate = dayjs();
      this.defaultMinDate = this.defaultMaxDate.subtract(1, 'y').set('h', 0).set('m', 0).set('s', 0).set('ms', 0);
      this.initializeCircleRequest(propertyDetail, userPreference);
    }
    if (userAccessControls) {
      this.hasAccessToComparableSales = userAccessControls.hasAccessToComparableSearch;
    }
    if (comparableSales) {

      let saleDate = comparableSales.request.lastDays ?
        `Last ${comparableSales.request.lastDays} days` :
        `${dayjs(comparableSales.request.minDate).format("MMM D, YYYY")} - ${dayjs(comparableSales.request.maxDate).format("MMM D, YYYY")}`;

      const maxAmount = comparableSales.request.maxAmount == DataService.SEARCH_COMPARABLES_INFINITY ? DataService.SEARCH_COMPARABLES_INFINITY_VALUE_DISPLAY : Number(comparableSales.request.maxAmount).toLocaleString();
      let saleAmount = `$${Number(comparableSales.request.minAmount).toLocaleString()} - $${maxAmount}`;

      const minArea =  comparablesLotSizeValues.find(lotSize => lotSize.value == comparableSales.request.minArea);
      const maxArea =  comparablesLotSizeValues.find(lotSize => lotSize.value == comparableSales.request.maxArea);

      //2G seems to always display imperial values here
      this.comparableSalesText = `Sale Date: ${saleDate}, Sale Amount: ${saleAmount}, Lot Size: ${minArea?.imperialDisplayValue} - ${maxArea?.imperialDisplayValue}.`;


    } else if (userPreference?.comparablesPreference?.searchCriteria) {
      this.usingDefaultCriteria = true;
      if (userPreference.comparablesPreference.searchCriteria.lastDaysDateRangeType == "CALENDAR") {
        this.comparableSalesText = `Sale Date: ${this.defaultMinDate.format("MMM D, YYYY")} - ${this.defaultMaxDate.format("MMM D, YYYY")}`;
      } else {
        this.comparableSalesText = `Sale Date: ${userPreference.comparablesPreference.searchCriteria.lastDaysDateRangeString}`;
      }
      const maxAmount = userPreference.comparablesPreference.searchCriteria.maxAmount == DataService.SEARCH_COMPARABLES_INFINITY ? DataService.SEARCH_COMPARABLES_INFINITY_VALUE_DISPLAY : Number(userPreference.comparablesPreference.searchCriteria.maxAmount).toLocaleString();
      this.comparableSalesText += `, Sale Amount: $${Number(userPreference.comparablesPreference.searchCriteria.minAmount).toLocaleString()} - $${maxAmount}, Lot Size: `;

      if (userPreference.genericPreference.isMetric) {
        this.comparableSalesText += `${userPreference.comparablesPreference.searchCriteria.minAreaMetricDisplayValue} - ${userPreference.comparablesPreference.searchCriteria.maxAreaMetricDisplayValue}sqmt.`;
      } else {
        this.comparableSalesText += `${userPreference.comparablesPreference.searchCriteria.minAreaImperialDisplayValue} - ${userPreference.comparablesPreference.searchCriteria.maxAreaImperialDisplayValue}.`;
      }
    }

  }

  hasAccessToComparableSales: boolean;
  comparableSalesText: string;
  mapResults: boolean = true;
  salesStatistics: boolean = true;
  usingDefaultCriteria: boolean = false;
  defaultMinDate: dayjs.Dayjs;
  defaultMaxDate: dayjs.Dayjs;

  // not used in display; only to pass to request param when connecting to backend
  pdfReportCircleRequest: PdfReportCircleRequest;

  private initializeCircleRequest(propertyDetail: PropertyDetail, userPreference: UserPreference | undefined) {
    this.pdfReportCircleRequest = new PdfReportCircleRequest();
    this.pdfReportCircleRequest.center = propertyDetail.centroid;

    if (userPreference?.comparablesPreference) {
      this.pdfReportCircleRequest.radiusInMeters = (userPreference.comparablesPreference.searchCriteria.radiusInMeters) ? Number(userPreference.comparablesPreference.searchCriteria.radiusInMeters) : 250;
      if (userPreference.comparablesPreference.searchCriteria.lastDaysDateRangeType == "CALENDAR") {
        // Temporary fix for issues with min/max date
        this.pdfReportCircleRequest.minDate = this.defaultMinDate.format('YYYY/MM/DD HH:mm');
        this.pdfReportCircleRequest.maxDate = this.defaultMaxDate.format('YYYY/MM/DD HH:mm');
      } else {
        this.pdfReportCircleRequest.lastDays = userPreference.comparablesPreference.searchCriteria.lastDaysDateRangeNumber;
      }
      this.pdfReportCircleRequest.minAmount = userPreference.comparablesPreference.searchCriteria.minAmount;
      this.pdfReportCircleRequest.maxAmount = userPreference.comparablesPreference.searchCriteria.maxAmount;
      this.pdfReportCircleRequest.condo = !!userPreference.comparablesPreference.searchCriteria.condo;
      this.pdfReportCircleRequest.freehold = !!userPreference.comparablesPreference.searchCriteria.freehold;

      if (userPreference?.genericPreference?.isMetric) {
        this.pdfReportCircleRequest.maxArea = Number(userPreference.comparablesPreference.searchCriteria.maxAreaMetricValue);
        this.pdfReportCircleRequest.minArea = Number(userPreference.comparablesPreference.searchCriteria.minAreaMetricValue);
      } else {
        this.pdfReportCircleRequest.maxArea = Number(userPreference.comparablesPreference.searchCriteria.maxAreaImperialValue);
        this.pdfReportCircleRequest.minArea = Number(userPreference.comparablesPreference.searchCriteria.minAreaImperialValue);
      }

    }

  }
}
