import {Component, Input} from '@angular/core';
import {Assessment} from "../../../../../core/model/assessment/assessment";
import {UserAccessControl} from "../../../../../core/model/user/user-access-control";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {DataService} from "../../../../../shared/service/data.service";
import {SatPopoverComponent} from "@ncstate/sat-popover";

@Component({
  selector: 'gema3g-site-structure-assessment-details',
  templateUrl: './site-structure-assessment-details.component.html',
  styleUrls: ['./site-structure-assessment-details.component.scss']
})
export class SiteStructureAssessmentDetailsComponent {
  @Input() assessment: Assessment = new Assessment();
  @Input() userAccessControls: UserAccessControl;
  protected readonly faCircleInfo = faCircleInfo;

  currentAssessedValueDisclaimerPopupMessage = DataService.CURRENT_ASSESSMENT_DISCLAIMER;

  async delayedMouseOut(popover: SatPopoverComponent): Promise<void> {
    setTimeout(() => {
      if (popover.isOpen()) {
        popover.close();
      }
    }, 5000);
  }
}
