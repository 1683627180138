import { Component, effect, inject, input, Input, OnChanges, OnInit, signal, SimpleChanges, WritableSignal } from '@angular/core';
import { LoggerService } from '../../../../shared/service/log/logger.service';
import { BannerMessage } from '../../../../core/model/message-center/banner-message';
import { UserService } from '../../../../shared/service/user.service';
import * as _ from 'lodash';
import { BaseUnsubscribe } from '../../base-unsubscribe/base-unsubscribe';
import { GoogleAnalyticsService } from '../../../../shared/service/google-analytics.service';
import { DataService } from '../../../../shared/service/data.service';
import { GA_Feature } from '../../../../shared/constant/google-analytics-constants';
import { StringUtility } from '../../../../shared/utility/string-utility';

@Component({
  selector: 'gema3g-ticker-tape',
  templateUrl: './ticker-tape.component.html',
  styleUrl: './ticker-tape.component.scss'
})
export class TickerTapeComponent extends BaseUnsubscribe implements OnInit, OnChanges {

  constructor() {
    super();
  }

  private loggerService = inject(LoggerService);
  private userService = inject(UserService);
  private gaService = inject(GoogleAnalyticsService);

  @Input() bannerMessages: BannerMessage | null;
  hasBannerMessages: WritableSignal<boolean> = signal(false);
  userBannerMessages: BannerMessage | null = null;
  tickerTipColor: string = '#D04A06'; //this color code needs to match the defined --color-dark-orange-secondary css color variable
  tickerTitle: string = DataService.IN_APP_TICKER_TAPE_TITLE;
  interval: number = 8000;
  messageList = new Array<string>();

  onClicked = (event: any) => {
    this.gaService.featureClicked(GA_Feature.IN_APP_TICKER_TAPE_CLICKED, 'Count', this.userBannerMessages?.getMessages().length.toString());
  }

  ngOnChanges(changes: SimpleChanges): void {
    let bannerMessages: any = changes['bannerMessages']?.currentValue;
    if (bannerMessages) {
      this.userBannerMessages = bannerMessages;
      if (this.userBannerMessages?.hasMessages()) {
        this.loggerService.logDebug(`${this.userBannerMessages?.getMessages().length} banner messages found for beid ${this.userService.getBEID()}`);

        this.hasBannerMessages.set(true);
        this.messageList = new Array<string>();

        this.userBannerMessages?.getMessages().map(message => {
          let messageLines: string[] = _.split(message.getBody(), '^');
          messageLines.forEach(line => {
            if (!_.isEmpty(line)) {
              line = line.trim();
              line = line.replace(/&nbsp;|<[^>]*>/g, "");
              let str = StringUtility.unescapeString(line);
              this.messageList.push(str);
            }
          });
        });
      } else {
        this.hasBannerMessages.set(false);
        this.loggerService.logDebug(`no banner messages found for beid ${this.userService.getBEID()}`);
      }
    } else {
      this.hasBannerMessages.set(false);
      this.userBannerMessages = null;
    }
  }

  ngOnInit(): void {
  }

}
