import { AfterViewInit, Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { PinListStaticStreetView, SignedImageData } from 'src/app/core/model/map/pinlist-static-streetview';
import { ComparablesService } from '../../../../shared/service/comparables.service';
import { PropertyDetail } from '../../../../core/model/property/property-detail';
import { MatDialog } from "@angular/material/dialog";
import { StreetViewDialogData } from '../../../../core/component/modal/street-view-dialog/street-view-dialog-data';
import { StreetViewDialogComponent } from '../../../../core/component/modal/street-view-dialog/street-view-dialog.component';
import { Centroid } from '../../../../core/model/spatial/centroid';
import { PropertyReportSectionEnum } from '../../../../core/enum/property-report-section-enum';
import { AdBannerRotationService } from '../../../../shared/service/ad-banner-rotation.service';
import { AdBanner } from '../../../../core/model/banner/ad-banner';
import { MainMapService } from '../../main-map/main-map.service';
import { AdBannerWeightEnum } from '../../../../core/enum/ad-banner-weight-enum';
import { PropertyReportComponentBase } from '../property-report-component-base';
import { UserAccessControl } from '../../../../core/model/user/user-access-control';
import { LroPolygonsService } from '../../../../shared/service/lro-polygons.service';
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { CondoDetail } from '../../../../core/model/property/condo-detail';
import _ from 'lodash';
import { AddressUtility } from "../../../../shared/utility/address.utility";
import { AdBannerAction } from "../../../../core/model/banner/ad-banner-action";
import { AdBannerActionEnum } from "../../../../core/enum/ad-banner-action-enum";
import { MeasurementUnitService } from "../../../../shared/service/measurement-unit.service";
import { lastValueFrom, takeUntil } from "rxjs";
import { EStoreProduct } from "../../../../core/model/product/e-store/e-store-product";
import { defaultResponsiveOptions } from "../../../../shared/constant/constants";
import { SelectedProperty } from "../../../../core/model/property/selected-property";
import { MetersToFeetWithUomPipe } from "../../../../shared/pipe/meters-to-feet-with-uom.pipe";
import { Clipboard } from "@angular/cdk/clipboard";
import { DataService } from "../../../../shared/service/data.service";
import { CarouselService } from '../../../../shared/service/carousel.service';
import { SquareMetersToSquareFeetWithUomPipe } from "../../../../shared/pipe/square-meter-to-square-feet-with-uom.pipe";
import { SquareMetersToAcersWithUomPipe } from "../../../../shared/pipe/square-meter-to-acres-with-uom.pipe";
import { UserService } from '../../../../shared/service/user.service';
import { EstoreProductTypeEnum } from '../../../../core/enum/estore-product-type.enum';
import { TooltipModule, TooltipOptions as NGTooltipOptions } from 'ng2-tooltip-directive';
import { LayoutComponent } from "../layout/layout.component";
import { CoreModule } from "../../../../core/core.module";
import { NgClass } from "@angular/common";
import { CarouselModule } from "primeng/carousel";
import { MatListModule } from "@angular/material/list";
import { MapMeasurementComponent } from "./map-measurement/map-measurement.component";
import { MapParcelComponent } from "./map-parcel/map-parcel.component";
import { GoogleAnalyticsService } from "../../../../shared/service/google-analytics.service";
import { MatIconModule } from '@angular/material/icon';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { PropertyDetailSectionEnum } from '../../../../core/enum/property-detail-section-enum';
import { GA_Feature, GA_Modal } from '../../../../shared/constant/google-analytics-constants';
import { SatPopoverComponent } from '@ncstate/sat-popover';

@Component({
  selector: 'gema3g-property-report-registry',
  standalone: true,
  templateUrl: './registry.component.html',
  styleUrls: ['./registry.component.scss'],
  imports: [
    LayoutComponent,
    CoreModule,
    NgClass,
    TooltipModule,
    MatTableModule,
    CarouselModule,
    MatListModule,
    MapMeasurementComponent,
    MapParcelComponent,
    MatIconModule
  ],
  providers: [MetersToFeetWithUomPipe, SquareMetersToSquareFeetWithUomPipe, SquareMetersToAcersWithUomPipe]
})
export class RegistryComponent extends PropertyReportComponentBase implements OnInit, AfterViewInit {

  constructor() {
    super();
  }

  private comparablesService = inject(ComparablesService);
  private dialog = inject(MatDialog);
  private adBannerRotationService = inject(AdBannerRotationService);
  private mainMapService = inject(MainMapService);
  private lroPolygonsService = inject(LroPolygonsService);
  private measurementUnitService = inject(MeasurementUnitService);
  private metersToFeetWithUomPipe = inject(MetersToFeetWithUomPipe);
  private clipboard = inject(Clipboard);
  private carouselService = inject(CarouselService);
  private userService = inject(UserService);
  private squareMetersToSquareFeetWithUom = inject(SquareMetersToSquareFeetWithUomPipe);
  private squareMetersToAcersWithUom = inject(SquareMetersToAcersWithUomPipe);
  private gaService = inject(GoogleAnalyticsService);

  @Input() userAccessControls: UserAccessControl;
  @Input() carouselProducts: EStoreProduct[] | undefined;
  @Input() selectedProperty: SelectedProperty;
  @ViewChild("copyPinPopOver") copyPinPopOver: any;
  subjectProperty: PropertyDetail;
  propertyReportSection = PropertyReportSectionEnum.REGISTRY;
  staticStreetViewUrl: string;
  //streetViewMap: google.maps.Map;

  geowarehouseAddress: string = DataService.STREETVIEW_ADDRESS_NOT_AVAILABLE;
  geowarehouseStreetViewPhotoLoading = DataService.STREETVIEW_PROPERTY_PHOTO_LOADING;
  geowarehouseStreetViewPhotoNotAvailable = DataService.STREETVIEW_PROPERTY_PHOTO_NOT_AVAILABLE;
  registryType: string = PropertyReportComponentBase.NOT_APPLICABLE;
  ownershipType: string;
  propertyType: string;
  ownerNames: string = PropertyReportComponentBase.NOT_APPLICABLE;
  pinCode: string;
  lro: string = 'Lro Not Available';
  pin: string;
  showCondoDetails: boolean;
  registrationDate: Date;

  condoDetailListItems: CondoDetail[] = [];
  condoDetailDataSource = new MatTableDataSource<CondoDetail>();
  displayedColumns: string[] = ['condoTypeVal', 'level', 'unit', 'pin'];
  condoDetailData: any[] = [];
  responsiveOptions = defaultResponsiveOptions;
  accuracyToolTip: string;
  sectionLoading: boolean = true;
  copiedPin: string = "Copied";
  faCircleInfo = faCircleInfo;
  hasAccessToLotMaps: boolean;

  @ViewChild('content') contentElement: ElementRef;
  @ViewChild('measurementAccuracyPopover') measurementAccuracyPopover: any;

  openPropertyReport = async (pin: string) => {
    this.propertyReportService.showPropertyReportByPinDeferData(pin);
  }

  showPropertyStreetViewDialog = () => {
    this.gaService.openModal(GA_Modal.PROPERTY_STREETVIEW);
    const dialogData = new StreetViewDialogData(`Property Street View PIN ${this.subjectProperty?.pii?.pin}`, this.subjectProperty.pii?.pin, new Centroid(this.subjectProperty.pii?.pinXy?.centroid.latitude, this.subjectProperty.pii?.pinXy?.centroid.longitude), 'Close');
    const dialogRef = this.dialog.open(StreetViewDialogComponent, { data: dialogData })
      .afterClosed();
  }

  showPropertyStreetView = () => {
    this.mainMapService.openStreetViewForPin(this.pin);
  }

  initializeAdBanners = () => {
  try {
    this.adBannerRotationService.resetPropertyReportSectionAds(PropertyReportSectionEnum.REGISTRY, 'LEFT');
    this.adBannerRotationService.resetPropertyReportSectionAds(PropertyReportSectionEnum.REGISTRY, 'RIGHT');

  
    let newLeftAdBanners: AdBanner[] = [];

    // Even if there are no left-side ads, setNextLeftSidebarAds should still be called
    this.adBannerRotationService.setNextLeftSidebarAds(newLeftAdBanners);
    let rightAdBannersBottom = new Set<AdBanner>();
    let rightAdBanners = new Set<AdBanner>();
    rightAdBanners.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/T_PR_H1.gif', new AdBannerAction(AdBannerActionEnum.OPEN_PARCEL_REGISTER_REPORT)));
    rightAdBanners.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/T_PR_H2.gif', new AdBannerAction(AdBannerActionEnum.OPEN_PARCEL_REGISTER_REPORT)));
    rightAdBanners.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/T_PR_H3.gif', new AdBannerAction(AdBannerActionEnum.OPEN_PARCEL_REGISTER_REPORT)));
    rightAdBanners.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/T_PR_H4.gif', new AdBannerAction(AdBannerActionEnum.OPEN_PARCEL_REGISTER_REPORT)));
    rightAdBanners.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/titlecheck/T_TC_H1.gif', new AdBannerAction(AdBannerActionEnum.OPEN_TITLE_CHECK_PARCEL_REGISTER_REPORT)));
    rightAdBanners.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/titlecheck/T_TC_H2.gif', new AdBannerAction(AdBannerActionEnum.OPEN_TITLE_CHECK_PARCEL_REGISTER_REPORT)));
    rightAdBanners.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/titlecheck/T_TC_H3.gif', new AdBannerAction(AdBannerActionEnum.OPEN_TITLE_CHECK_PARCEL_REGISTER_REPORT)));
    rightAdBanners.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/titlecheck/T_TC_H4.gif', new AdBannerAction(AdBannerActionEnum.OPEN_TITLE_CHECK_PARCEL_REGISTER_REPORT)));
    
    rightAdBannersBottom.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/B_PR_S1.gif', new AdBannerAction(AdBannerActionEnum.OPEN_PARCEL_REGISTER_REPORT)));
    rightAdBannersBottom.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/B_PR_S2.gif', new AdBannerAction(AdBannerActionEnum.OPEN_PARCEL_REGISTER_REPORT)));
    rightAdBannersBottom.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/B_PR_S3.gif', new AdBannerAction(AdBannerActionEnum.OPEN_PARCEL_REGISTER_REPORT)));
    rightAdBannersBottom.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/B_PR_S4.gif', new AdBannerAction(AdBannerActionEnum.OPEN_PARCEL_REGISTER_REPORT)));
    rightAdBannersBottom.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/titlecheck/B_TC_S1.gif', new AdBannerAction(AdBannerActionEnum.OPEN_TITLE_CHECK_PARCEL_REGISTER_REPORT)));
    rightAdBannersBottom.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/titlecheck/B_TC_S2.gif', new AdBannerAction(AdBannerActionEnum.OPEN_TITLE_CHECK_PARCEL_REGISTER_REPORT)));
    rightAdBannersBottom.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/titlecheck/B_TC_S3.gif', new AdBannerAction(AdBannerActionEnum.OPEN_TITLE_CHECK_PARCEL_REGISTER_REPORT)));
    rightAdBannersBottom.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/parcelregister/titlecheck/B_TC_S4.gif', new AdBannerAction(AdBannerActionEnum.OPEN_TITLE_CHECK_PARCEL_REGISTER_REPORT)));

    let condoRightAdBanners = new Set<AdBanner>();
    if (this.showCondoDetails) {
      condoRightAdBanners.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/conduit/Conduit_1.jpg', new AdBannerAction(AdBannerActionEnum.OPEN_CONDUIT)));
      //condoRightAdBanners.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.REGISTRY, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/conduit/Conduit_2.jpg', new AdBannerAction(AdBannerActionEnum.OPEN_CONDUIT)));
    }

    let newRightAdBanners: AdBanner[] = [];
    newRightAdBanners.push(this.adBannerRotationService.getNextAdBanner(rightAdBanners));


		let newRightAdBannersBottom: AdBanner[] = [];
		newRightAdBannersBottom.push(this.adBannerRotationService.getNextAdBanner(rightAdBannersBottom));

    if (this.userService.hasAccessToProduct(EstoreProductTypeEnum.CONDO_STATUS_CERTIFICATE) && this.showCondoDetails) {
      newRightAdBanners.push(this.adBannerRotationService.getNextAdBanner(condoRightAdBanners));
    }

    this.adBannerRotationService.setNextRightSidebarAds(newRightAdBanners);
		this.adBannerRotationService.setNextRightBottomSidebarAds(newRightAdBannersBottom);

  } catch (e) {
    this.loggerService.logError(`error initializing ${PropertyReportSectionEnum.REGISTRY} ads`);
  }
}


  refreshUI = () => {

    this.showCondoDetails = false;
    // type could be 'SUITE' or 'LOCKER_OR_PARKING' for condos
    if (this.subjectProperty.condoDetail?.type) {
      this.showCondoDetails = true;
    }

    this.initializeAdBanners();

    this.comparablesService.getStaticStreetView(this.subjectProperty.pii?.pin, 1200, 330, 'STREET_VIEW', 120)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((imageUrl: string) => {
        if (imageUrl) {
          this.staticStreetViewUrl = imageUrl;
        }
      });

    if (this.subjectProperty.pii?.address?.fullAddress) {
      var fullAddress = this.subjectProperty.pii?.address?.fullAddress;
      var pincodenumber = fullAddress.slice(fullAddress.length - 6, fullAddress.length);
      if (AddressUtility.checkPostal(pincodenumber)) {
        this.geowarehouseAddress = fullAddress.slice(0, fullAddress.length - 6);
        this.pinCode = fullAddress.slice(fullAddress.length - 6, fullAddress.length);
      } else {
        this.geowarehouseAddress = fullAddress;
        this.pinCode = "";
      }
    }


    if (this.subjectProperty.ownerNames && !_.isEmpty(this.subjectProperty.ownerNames)) {
      this.ownerNames = this.subjectProperty.ownerNames.join("; ");
    }

    this.populateAccuracyToolTip();

    if (this.subjectProperty.pii?.lro) {
      this.lro = this.lroPolygonsService.getNameById(this.subjectProperty.pii.lro)! + ' (' + this.subjectProperty.pii.lro + ')';
    }

    this.pin = this.subjectProperty.pii?.pin;

    // condo details
    if (this.showCondoDetails) {

      if (this.subjectProperty.relatedCondoDetails && this.subjectProperty.relatedCondoDetails?.length > 1) {
        let pin: string = this.subjectProperty.pii.pin;

        this.condoDetailListItems = [];
        this.subjectProperty.relatedCondoDetails.forEach(value => {
          let condoDetail: CondoDetail = new CondoDetail();
          var condoTypeVal = value.type;

          if (value.type != null && value.type == 'LOCKER_OR_PARKING') {
            if (value.conduitDatas != null && value.conduitDatas.length > 0) {
              var condoDataObj = value.conduitDatas[0];
              if (condoDataObj != null && condoDataObj.unitType != null) {

                if (condoDataObj.unitType === "Residential") {
                  condoTypeVal = "OTHER";
                } else {
                  condoTypeVal = condoDataObj.unitType;
                  condoTypeVal = _.upperCase(condoTypeVal);
                }
              }
            } else {
              condoTypeVal = "OTHER";
            }
          }

          var unitNum = PropertyReportComponentBase.NOT_APPLICABLE;
          if (value.pin === value.suitePin) {
            if (value.conduitDatas != null && value.conduitDatas.length > 0) {
              var condoDataObj = value.conduitDatas[0];
              if (condoDataObj != null && condoDataObj.unitNum != null) {
                unitNum = condoDataObj.unitNum;
              }
            }
          }

          condoDetail.condoTypeVal = condoTypeVal;
          condoDetail.pin = value.pin;
          condoDetail.level = value.levelNumber;
          //@ts-ignore
          condoDetail.unit = <number>value.unitNumber;

          if (value.pin === pin) {
            condoDetail.condoType = 'Primary';
          } else {
            condoDetail.condoType = 'Related';
          }

          this.condoDetailListItems.push(condoDetail);

        });
        this.condoDetailListItems = _.orderBy(this.condoDetailListItems, ['condoTypeVal'], ['asc']);
        this.condoDetailDataSource = new MatTableDataSource(this.condoDetailListItems);

      } else {
        this.condoDetailListItems = [];
        let condoDetail: CondoDetail = new CondoDetail();
        condoDetail.condoType = 'Primary';
        condoDetail.level = this.subjectProperty.condoDetail.levelNumber;
        //@ts-ignore
        condoDetail.unit = this.subjectProperty.condoDetail.unitNumber;
        condoDetail.pin = this.subjectProperty.condoDetail.pin;

        //@ts-ignore
        if (this.subjectProperty.condoDetail.type == "LOCKER_OR_PARKING") {
          condoDetail.condoTypeVal = 'OTHER';
        } else {
          condoDetail.condoTypeVal = this.subjectProperty.condoDetail.type;
        }

        this.condoDetailListItems.push(condoDetail);
        //this.condoDetailListItems = _.orderBy(this.condoDetailListItems, ['condoType', 'level', 'unit'], ['asc', 'asc', 'asc']);
        this.condoDetailListItems = _.orderBy(this.condoDetailListItems, ['condoTypeVal'], ['asc']);
        this.condoDetailDataSource = new MatTableDataSource(this.condoDetailListItems);
      }
    }

    this.sectionLoading = false;
  }

  get showNavigators(): boolean {
    return this.carouselService.showNavigators(this.carouselProducts?.length);
  }

  get perimeter(): string {
    return this.metersToFeetWithUomPipe.transform(this.subjectProperty?.pii?.pinXy?.perimeter, 2)
  };

  ngOnInit(): void {
    this.hasAccessToLotMaps = this.userService.hasAccessToProduct(EstoreProductTypeEnum.TERANET_LOT_DETAILS);
    this.initializeSubjectPropertyListener();
  }

  initializeSubjectPropertyListener = () => {
    this.propertyReportService.subjectProperty$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newPropertyDetail) => {
        this.sectionLoading = true;
        this.subjectProperty = newPropertyDetail;
        this.selectedProperty.loadFromPropertyDetail(newPropertyDetail, true);
        this.selectedProperty.isSelected = true;
        this.refreshUI();
      });
  }

  ngAfterViewInit(): void {
  
  }

  async delayedMouseOut(popover: SatPopoverComponent): Promise<void> {
    setTimeout(() => {
      if (popover.isOpen()) {
        popover.close();
      }
    }, 3000);
  }

  copyMeasurementsToClipboard() {
    this.clipboard.copy(this.measurements);
  }

  populateAccuracyToolTip() {
    this.accuracyToolTip = DataService.DEFAULT_ACCURACY_MEASUREMENT;
    if (this.subjectProperty?.pii?.lotMeasurementAccuracy == 'LOW') {
      this.accuracyToolTip = DataService.LOW_ACCURACY_MEASUREMENT;
    }
    if (this.subjectProperty?.pii?.lotMeasurementAccuracy == 'HIGH') {
      this.accuracyToolTip = DataService.HIGH_ACCURACY_MEASUREMENT;
    }
  }

  isCurrentPin(row: CondoDetail): boolean {
    return row.pin == this.selectedProperty?.pin;
  }

  copyPin = () => {
    navigator?.clipboard?.
    writeText(this.pin)
      .then(() => {
        this.copiedPin = "Copied";
        this.copyPinPopOver.open();
        setTimeout(() => {
          this.copyPinPopOver.close();
          this.gaService.featureClicked(GA_Feature.COPY_PIN, 'PIN', this.pin);
        }, 4000);
      });
  }

  get measurements(): string {
    if (Array.isArray(this.subjectProperty?.pii?.measuredLineSegment)) {
      return this.subjectProperty.pii.measuredLineSegment
        .map(value => {
          return this.metersToFeetWithUomPipe.transform(value.measurement, 2);
        })
        .join(' x ');
    }
    return '';
  }

  get area(): string {
    let x = this.subjectProperty.condoDetail;
    return this.squareMetersToSquareFeetWithUom.transform(this.subjectProperty?.pii?.pinXy?.area, 2);
  }

  get areaAdditional(): string {
    if (this.measurementUnitService.isUomInMeters) {
      return '';
    }
    const areaAddditional = this.squareMetersToAcersWithUom.transform(this.subjectProperty?.pii?.pinXy?.area, 3);

    return areaAddditional !== 'N/A' ? `(${areaAddditional})` : '';
  }
}