<h1 id="spd-h1-title" mat-dialog-title>
  Select Comparables Report
</h1>
<div class="dialog-content" mat-dialog-content>
  <h3 class="mt-4">{{addToReport}}</h3>
  @if (!isReportsLoading) {
    <mat-radio-group
      [ngModelOptions]="{standalone: true}"
      [(ngModel)]="selectedReportId"
      (change)="radioChange($event)"
    >
      @for (option of availableReports; track $index) {
        @if ($index === 0) {
          <div class="section-header">Last Edited Report</div>
        } @else if ($index === 1) {
          <div class="section-header">Create New Comparables Report</div>
          <!-- <div>({{data.pii.fullAddress}})</div> -->
          <div>({{data.pii.address.fullAddress}})</div>
        } @else if ($index === 2) {
          <div class="section-header">Recent Reports</div>
        }
        <div>
          <mat-radio-button class="example-radio-button" [value]="option.reportId">{{ option.label }}</mat-radio-button>
        </div>
      }
    </mat-radio-group>

  } @else {
    <div class="loading">
      <img id="reg-img-refresh" src="assets/img/icon-refresh.gif">
    </div>
  }
</div>

<mat-divider></mat-divider>

<div align="end" mat-dialog-actions>
  <button id="btn-add-display" mat-button class="dialog-accept-btn" color="primary" mat-dialog-close (click)='goToReport(true)'>Add & Display</button>
  <button id="btn-add" mat-button class="dialog-accept-btn" color="primary" mat-dialog-close (click)='goToReport(false)'>Add</button>
  <button id="btn-close" mat-button mat-dialog-close class="dialog-close-btn">Cancel</button>
</div>
