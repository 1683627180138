import {Component, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {OmnibarStateService} from "../../../../shared/service/search/omnibar-state.service";
import {BaseUnsubscribe} from "../../../../core/component/base-unsubscribe/base-unsubscribe";
import {lastValueFrom, takeUntil} from "rxjs";
import {ScreenNameEnum} from "../../../../core/enum/screen-name.enum";
import {ScreenManager} from "../../../../shared/service/screen-manager.service";
import {OmnibarSearchService} from "../../../../shared/service/search/omnibar-search.service";
import {LoggerService} from "../../../../shared/service/log/logger.service";
import {SearchResult} from "../../../../core/model/search-result/search-result";
import {Router} from "@angular/router";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {SearchResultsViewType} from "../../../../core/enum/search-results-view-type";
import {LroPolygonsService} from "../../../../shared/service/lro-polygons.service";
import {OwnerToPropertyMap} from "../../../../core/model/search-result/owner-to-property-map";
import {PinOrArn} from "../../../../core/model/property/pin-or-arn";
import {OmnibarSearchTypeEnum} from "../../../../core/enum/omnibar-search-type-enum";
import {CondoLevel} from "../../../../core/model/search-result/condo-level";
import {CondoSearchParameters, LotSearchParameters, SearchParameters} from "../../../../shared/service/search/search-parameters";
import {MainMapService} from "../../main-map/main-map.service";
import {CondoSummary} from "../../../../core/model/property/condo-summary";
import {DataService} from '../../../../shared/service/data.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import _ from "lodash";
import {defaultErrorMatSnackBarConfig} from '../../../../shared/constant/constants';
import {HomeService} from "../../../../shared/service/home.service";
import {HomeParam} from "../../../../core/model/home/home-param";


@Component({
  selector: 'gema3g-search-results-container',
  templateUrl: './search-results-container.component.html',
  styleUrls: ['./search-results-container.component.scss'],
  animations: [
    trigger('expandDiv',[
      state('in', style({height: '*'})),
      state('out', style({height: '0'})),
      transition( 'in => out', animate('500ms ease-out')),
      transition( 'out => in', animate('500ms ease-in'))
    ]),
  ]
})
export class SearchResultsContainerComponent extends BaseUnsubscribe implements OnInit {

  constructor(
    private screenManager: ScreenManager,
    private omnibarStateService: OmnibarStateService,
    private omnibarSearchService: OmnibarSearchService,
    private mainMapService: MainMapService,
    private loggerService: LoggerService,
    private router: Router,
    private lroPolygonsService: LroPolygonsService,
    private _snackBar: MatSnackBar) {
    super();
  }

  private homeService = inject(HomeService);

  @Output() mobileFullScreen = new EventEmitter<boolean>();

  showResults: boolean = false;
  showNoResultsMessage: boolean = true;
  showNoMoreResultsMessage: boolean = false;
  showNoMoreResultsMessageNoThreshold: number = 8;
  isSearching: boolean = false;
  isSearchingForNextPage: boolean = false;
  searchResult: SearchResult;
  searchType: string;
  searchPage: number;
  expanded: boolean = true;
  ownerToPropertyMap:OwnerToPropertyMap[] = [];
  viewType: SearchResultsViewType = SearchResultsViewType.Main;
  lro: string | null = this.lroPolygonsService.getCurrentLro();
  ownerWithMultiPropertyMap:OwnerToPropertyMap[] = [];
  condoLevels: CondoLevel[] = [];
  condoAddress: string;
  backButtonDisabledForCondo: boolean = false;
  secondLevelSearchCompleted: boolean = false;
  isMobileFullScreen: boolean = false;

  async ngOnInit(): Promise<void> {

    this.screenManager.getObservableScreen(ScreenNameEnum.OMNIBAR_SEARCH_RESULTS)!
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(visible => {
        this.showResults = visible;
        this.ownerToPropertyMap = [];
        this.searchResult = new SearchResult();
      })

    this.omnibarStateService.omnibarState$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(newStatus => {
        this.isSearching = newStatus.searchInitiated;
        if(this.isSearching){
          this.ownerToPropertyMap = [];
          this.viewType = SearchResultsViewType.Main;
        }
        this.showNoResultsMessage = !newStatus.searchResultsFound && !newStatus.searchInitiated;
        //if (this.showNoResultsMessage) this.noResultsFound();
      });

    this.omnibarSearchService.lastSearchResults$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(searchResult => {
        this.applyLastSearchResults(searchResult);
      });

    this.omnibarSearchService.lastSearchParams$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(searchParam => {
        this.applyLastSearchParams(searchParam);
      });
    this.lroPolygonsService.lroState$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(lroState => {
        this.lro = lroState.lroId;
      });

    this.screenManager.getObservableScreen(ScreenNameEnum.OMNIBAR_CONDO_SEARCH_RESULTS)!
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(visible => {
        // this gets triggered on a click on "View units" property modal
        this.backButtonDisabledForCondo = true;
        this.showResults = visible;
        if (this.showResults) {
          this.viewType = SearchResultsViewType.Condo;
        }
        if (this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
          this.screenManager.hideScreen(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS);
        }
      });

    this.omnibarSearchService.lastSecondLevelSearchResults$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(searchResult => {
        this.applySecondLevelSearchResults(searchResult);
      });

    this.omnibarSearchService.lastSecondLevelSearchParams$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(searchParam => {
        if (searchParam instanceof CondoSearchParameters) {
          this.condoAddress = searchParam.condoAddress;
        }
      });
  }

  private applySecondLevelSearchResults(searchResult: any) {
    if (searchResult instanceof SearchResult) {
      this.secondLevelSearchCompleted = (searchResult.page + 1 >= searchResult.pagesTotal);
      if (this.ownerWithMultiPropertyMap.length > 0) {
        this.ownerWithMultiPropertyMap[0].ownerToPropertyMapItems.push(...searchResult.ownerToPropertyMap[0].ownerToPropertyMapItems);
      } else {
        this.ownerWithMultiPropertyMap.push(...searchResult.ownerToPropertyMap);
      }
    } else if (Array.isArray(searchResult) && searchResult[0] instanceof CondoLevel) {
      this.condoLevels = [...searchResult];
      this.showNoResultsMessage = this.condoLevels.length == 0;
      if (this.showNoResultsMessage) this.noResultsFound();
    }
  }

  private applyLastSearchParams(searchParam: any) {
    if (searchParam) {
      if (searchParam instanceof SearchParameters) {
        this.loggerService.logInfo('Search param:"', searchParam);
        this.searchType = searchParam.searchType;
        if (searchParam.searchType == OmnibarSearchTypeEnum.INSTRUMENT_VALUE || searchParam.searchType == OmnibarSearchTypeEnum.LOT_CONCESSION_VALUE) {
          this.viewType = SearchResultsViewType.Instrument;
        } else {
          this.viewType = SearchResultsViewType.Main;
        }
        this.searchPage = searchParam.page;
      } else if (searchParam instanceof CondoSearchParameters) {
        this.condoAddress = searchParam.condoAddress;
      } else if (searchParam instanceof LotSearchParameters) {
        this.showResults = false;
      }
    }
  }

  applyLastSearchResults(searchResult: any) {
    this.showNoMoreResultsMessage = false;
    if (searchResult instanceof SearchResult) {
      if (this.searchResult.initialized) {
        // need to remove duplicates as backend returns on block search the first block a couple of times.
        this.searchResult.copyReturnedResults(searchResult);
      } else {
        this.searchResult = searchResult;
        this.searchResult.initialized = true;
      }
      if (this.searchResult?.searchResult?.length == 1) {
        this.searchResult.searchResult[0].detailsExpanded = true;
      }
      this.ownerToPropertyMap = [...this.ownerToPropertyMap,...searchResult.ownerToPropertyMap];

      if(this.ownerToPropertyMap?.length) {
        this.mainMapService.renderOwnersPropertyMarkers(this.ownerToPropertyMap, true);
      } else if (this.searchResult.searchResult.length){
        this.mainMapService.renderPiiMarkers(this.searchResult.searchResult, true);
      }

      if(!_.isEmpty( searchResult.document) && this.viewType != SearchResultsViewType.Instrument){
        this.viewType = SearchResultsViewType.Instrument;
      }
    }
  }

  noResultsFound() {
    this._snackBar.open(DataService.SEARCH_RESULTS_NOT_FOUND, 'Close', defaultErrorMatSnackBarConfig);
    this.showNoResultsMessage = false;
  }

  get ownerSearchUnits(): number {
    if (this.searchResult?.ownerToPropertyMap) {
      return this.searchResult.ownerToPropertyMap.length;
    }
    return 0;
  }

  get addressSearchUnits(): number {
    if (this.searchResult?.activityType == "PIN_BLOCKNUM_SEARCH" && this.searchResult?.ownerToPropertyMap == null && this.searchResult?.searchResult?.length < 2) {
      return this.searchResult.searchResult.length;
    }
    if (this.searchResult?.searchResult?.length != 0) {
      return this.searchResult?.searchResult?.length;
    }
    return 0;
  }

  get totalSearchResults(): number {
    return this.searchResult ? this.searchResult.itemsTotal : 0;
  }

  get nextItemReturned(): number {
    if (this.searchResult?.activityType == "PIN_BLOCKNUM_SEARCH" && this.searchResult?.ownerToPropertyMap == null && this.searchResult?.searchResult?.length < 2) {
      return -1;
    }
    if (this.searchResult?.searchResult?.length != 0) {
      return this.searchResult?.nextItemAddress;
    }
    return 0;
  }

  get nextItemReturnedOwner(): number {
    if (this.searchResult?.activityType == "PIN_BLOCKNUM_SEARCH" && this.searchResult?.ownerToPropertyMap == null && this.searchResult?.searchResult?.length < 2) {
      return 0;
    }
    return this.searchResult?.nextItemOwner;
  }

  get totalInstrumentPage(): number {
    return this.searchResult ? this.searchResult?.pagesTotal : 0;
  }

  get nextInstrumentPage(): number {
    if (this.searchResult?.searchResult?.length == 0 && this.searchResult?.ownerToPropertyMap == null && this.searchResult?.document != null) {
      return this.searchResult?.page;
    }
    if (this.searchResult?.searchResult?.length != 0 && this.searchResult?.ownerToPropertyMap == null) {
      if (this.totalInstrumentPage != null || (this.searchResult?.document != null || this.searchType == 'instrument')) {
        return this.searchResult?.page;
      }
    }
    return 0;
  }

  get endOfResults(): boolean {
    //return this.searchPage > this.searchResult.pagesTotal;
    return (
      this.isSecondLevelActive && this.secondLevelSearchCompleted ||
      this.nextItemReturned == -1 && this.nextItemReturnedOwner == 0 ||
      this.nextItemReturned == -1 && this.nextItemReturnedOwner == -1 ||
      this.nextItemReturnedOwner == -1 && this.nextItemReturned == 0 ||
      this.nextItemReturnedOwner == 0 && this.nextItemReturned == 0 ||
      (this.nextInstrumentPage >= this.totalInstrumentPage && this.searchType == 'instrument') || this.nextInstrumentPage == -1)
  }

  get searchResultsCounter(): string {
    //const calculatedTotalUnits = this.ownerSearchUnits + this.addressSearchUnits;
    let calculatedTotalUnits = 0;
    if(this.ownerToPropertyMap) {
      calculatedTotalUnits = this.ownerToPropertyMap.length;
    }
    if (this.searchResult?.searchResult){
      calculatedTotalUnits += this.searchResult.searchResult.length;
    }
    return ' Displaying' +
      ((calculatedTotalUnits == this.totalSearchResults || calculatedTotalUnits == 1 || this.endOfResults || this.searchType === 'lotcon') ? " " : " the first ") +
      calculatedTotalUnits +
      (calculatedTotalUnits == 1 ? " result" : " results");
  }

  async goToProperty(event: PinOrArn) {
    if (event?.pin || event?.arn) {
      if (this.viewType != SearchResultsViewType.Main){
        this.viewType = SearchResultsViewType.Main;
      }
      this.homeService.appendChildrenRequest(HomeParam.fromPinOrArn(event));
      this.mobileFullScreenMode(false);
    }
  }

  get isSecondLevelActive(): boolean {
    return (this.viewType == SearchResultsViewType.Owner || this.viewType == SearchResultsViewType.Condo);
  }

  async onScroll($event: Event) {
    if(this.isSearchingForNextPage || this.endOfResults){
      if(this.endOfResults){
        this.showNoMoreResultsMessage =
          (this.ownerToPropertyMap?.length > this.showNoMoreResultsMessageNoThreshold ||
          this.searchResult?.searchResult?.length > this.showNoMoreResultsMessageNoThreshold);
      }
      return;
    }
    const content = $event.target as HTMLElement;
    if(content) {
      const currentPosition = content?.scrollTop + content?.clientHeight;
      if(currentPosition >= content.scrollHeight) {
        this.isSearchingForNextPage = true;
        try {
          if(this.isSecondLevelActive){
            // the reason the results are not used here is because of subscribers from this component that listen for results of this search
            const nextPage = await lastValueFrom(this.omnibarSearchService.getNextPageForSecondLevelLastSearch());
          } else {
            const nextPage = await lastValueFrom(this.omnibarSearchService.getNextPageForLastSearch());
          }
        } finally {
          this.isSearchingForNextPage = false;
        }
      }
    }
  }

  backToMainView() {
    this.viewType = SearchResultsViewType.Main;
    this.mainMapService.renderOwnersPropertyMarkers(this.ownerToPropertyMap, true);
  }

  async onOwnerClick($event: OwnerToPropertyMap) {
    this.ownerWithMultiPropertyMap = [];
    let searchResult = await lastValueFrom(this.omnibarSearchService.searchPropertiesByOwnerFullName($event.name, this.lro!));
    if(searchResult){
      this.mainMapService.renderOwnerItems(searchResult?.ownerToPropertyMap[0]?.ownerToPropertyMapItems, true);
      this.viewType = SearchResultsViewType.Owner;
    }
  }

  async goToCondo(condoSummary: CondoSummary) {
    this.backButtonDisabledForCondo = false;
    await this.mainMapService.openCondoSearch(condoSummary);
    this.viewType = SearchResultsViewType.Condo;
  }

  mobileFullScreenMode(result: boolean) {
    this.mobileFullScreen.emit(result);
    this.isMobileFullScreen = result;
  }
}
