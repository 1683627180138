<div class="sidebar-container no-print">
 <!-- Top Ad Banners Section -->
  <div class="top-ads"> 
  @for (adBanner of newAdBanners; track $index) {
    @if (adBanner.imageSource.startsWith('assets/img/store-front/parcelregister') && adBanner.imageSource.endsWith('.mp4')) {
      <div
        class="video-wrapper"
        style="cursor: pointer; display: inline-block;"
        (click)="invokeAction(adBanner.action, $index)"
      >
        <video autoplay muted loop playsinline style="width: 100%; height: auto; border: none;">
          <source [src]="adBanner.imageSource" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    } @else {
      <img [src]="adBanner.imageSource" id="right-sidebar-{{adBanner.adType}}-{{adBanner.imageSource}}" (click)="invokeAction(adBanner.action, $index)" />
    }
  }
  </div>

  @if (isPropertySummary) {
    <img id="right-sidebar-img-title-reports" src="assets/img/estore/images/catalogue/View_other_title_reports.jpg" (click)="invokeAction(viewStatReportsAction, -1)">
    @if (condoBanner) {
      <img id="right-sidebar-{{condoBanner.adType}}-{{condoBanner.imageSource}}" class="mt-auto" [ngClass]="{'lg:mb-[282px]': newRightBottomAdBanners.length == 0}" src="{{condoBanner?.imageSource}}" (click)="invokeAction(condoBanner.action, -1)">
    }
  }

 <!-- Bottom Ad Banners Section -->
  <div class="bottom-ads">
  @for (adBanner of newRightBottomAdBanners; track $index) {
    @if (adBanner.imageSource.endsWith('.mp4')) {
     <video controls autoplay muted loop class="bottom-ad">
          <source [src]="adBanner.imageSource" type="video/mp4" />
          Your browser does not support the video tag.
      </video>
    } @else {
      <img [src]="adBanner.imageSource" id="right-sidebar-{{adBanner.adType}}-{{adBanner.imageSource}}" (click)="invokeAction(adBanner.action, $index)" />
    }
  }
</div>