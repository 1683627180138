import { inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ComparableSalesResultPayload } from "../../../core/model/comparables/comparable-sales-result-payload";
import { ScreenOrientation } from '../../../core/enum/screen-orientation-enum';
import { ScreenDisplay } from "../../../core/enum/screen-display-enum";
import { SearchComparablesResultSnapshot } from "../../../core/model/search-result/comparables-result-snapshot";
import { LoggerService } from "../log/logger.service";
import { SearchComparablesFormWrapper } from "../../../core/model/comparables/search-comparables-form-wrapper";
import { LocalStorageKey } from "../../constant/constants";
import { PIIService } from "../pii.service";
import { ComparableSale } from "../../../core/model/comparables/comparable-sales-response";

@Injectable({
    providedIn: 'root'
})
export class SearchComparablesResultService {
    constructor() {
    }

    private loggerService: LoggerService = inject(LoggerService);
    private piiService: PIIService = inject(PIIService);

    private _searchResultsSnapshot = new BehaviorSubject<SearchComparablesResultSnapshot | null>(null);
    private searchResultsSnapshot$ = this._searchResultsSnapshot.asObservable();

    private _screenOrientation = new BehaviorSubject<ScreenOrientation | null>(null);
    screenOrientation$ = this._screenOrientation.asObservable();

    private _screenDisplay = new BehaviorSubject<ScreenDisplay | null>(null);
    screenDisplay$ = this._screenDisplay.asObservable();

    private _searchResultsMapBounds = new BehaviorSubject<SearchComparablesFormWrapper | null>(null);
    private searchResultsMapBounds$ = this._searchResultsMapBounds.asObservable();

    getSearchResultsSnapshotObservable = () => {
        return this.searchResultsSnapshot$;
    }

    updateSearchResultsSnapshot = (resultsSnapshot: SearchComparablesResultSnapshot | null) => {
        this._searchResultsSnapshot.next(resultsSnapshot);
    }

    clearSearchResultsSnapshot = () => {
        this.updateSearchResultsSnapshot(null);
    }

    getSearchResultsSnapshotValue = () => {
        return this._searchResultsSnapshot.getValue();
    }

    isPinIncludedInSearchComparablesResult = (pin: string): boolean => {
        let included: boolean = false;

        let snapshot: SearchComparablesResultSnapshot | null = this.getSearchResultsSnapshotFromStorage();

        if (snapshot) {
            included = snapshot.results?.response?.sales?.some(sale => sale.pin == pin);
        }

        return included;
    }

    isPinOpenedFromSearchResults(pin: string): boolean {
        let flag: boolean = false;

        if (this._searchResultsSnapshot.getValue() != null && this._searchResultsSnapshot.getValue()?.pin == pin) {
            flag = true;
        }

        this.loggerService.logDebug(`pin ${pin} opened from search results snapshot? ${flag}`);
        return flag;
    }

    isLastSearchResultsExist = (): boolean => {
        return this.getSearchResultsSnapshotFromStorage()? true : false;
    }

    getSearchResultsSnapshotFromStorage = (): SearchComparablesResultSnapshot | null => {
        let serializedSnapshot: string | null = localStorage.getItem(LocalStorageKey.comparablesSalesSnapshot);
        let storageSnapshot: SearchComparablesResultSnapshot | null = null;

        if (serializedSnapshot) {
            storageSnapshot = (<SearchComparablesResultSnapshot>JSON.parse(serializedSnapshot));
        }

        return storageSnapshot;
    }

    getSaleMarkerPin = (sale: ComparableSale) => {
        let pin: string = '';
        if (sale.condo == 'Y') {
          pin = this.piiService.getCondoBlockPin(sale.pin);
        } else {
          pin = sale.pin;
        }
    
        return pin;
    }

    getSearchResultsMapBoundsObservable = () => {
        return this.searchResultsMapBounds$;
    }

    changeScreenOrientation = (orientation: ScreenOrientation) => {
        this._screenOrientation.next(orientation);
    }

    changeScreenDisplay = (display: ScreenDisplay) => {
        this._screenDisplay.next(display);
    }

    resetScreenOrientation = () => {
        this._screenOrientation.next(null);
    }

    resetScreenDisplay = () => {
        this._screenDisplay.next(null);
    }

    adjustSearchResultsMapBounds = (formWrapper: SearchComparablesFormWrapper | null) => {
        this._searchResultsMapBounds.next(formWrapper);
    }
}