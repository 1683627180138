<div class="gema3g-ss-container">
  <div class="gema3g-ss-container__inner">

    <div class="gema3g-ss-container__inner-grid-2">
      <div class="l-column">
        <h4 id="ss-h4-piv-{{assessment.rollNumber}}">4 Year Phased In Assessed Values</h4>
        <div [satPopoverAnchor]="phasedInDisclaimerPopover" class="label text-os-bold" [satPopoverAnchor]="phasedInDisclaimerPopover" (mouseover)="phasedInDisclaimerPopover.open()" (mouseleave)="delayedMouseOut(this.phasedInDisclaimerPopover)">
          <fa-icon
            id="ss-icon-phd-tooltip"
            class="fa-lg text-[#428BCA]"
            [icon]="faCircleInfo">
          </fa-icon>
          <sat-popover #phasedInDisclaimerPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out" closeTransition="200ms ease-in">
            <gema3g-tooltip [content]="phasedInDisclaimerPopupMessage"></gema3g-tooltip>
          </sat-popover>
        </div>
      </div>
      <div class="r-column">
        <table id="ss-tbl-piv-{{assessment.rollNumber}}" class="h-[100%]" [dataSource]="phasedInDataSource" class="mat-elevation-z2 standard-table" mat-table matSort>

          <ng-container matColumnDef="year">
            <th id="ss-tbl-hdr-piv-ty-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Taxation Year</th>
            <td id="ss-tbl-data-piv-ty-{{assessment.rollNumber}}-{{element.taxYear}}" *matCellDef="let element" mat-cell>
              {{element.taxYear}}
            </td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th id="ss-tbl-hdr-piv-av-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Phased-in Assessment</th>
            <td id="ss-tbl-data-piv-av-{{assessment.rollNumber}}-{{element.taxYear}}" *matCellDef="let element" mat-cell>
              {{element.assessmentValue !=null ? (element.assessmentValue | currency : 'USD' : 'symbol' : '1.0-0') : "N/A" }}
            </td>
          </ng-container>

          <tr id="ss-tbl-row-piv-header-{{assessment.rollNumber}}" mat-header-row *matHeaderRowDef="columns"></tr>
          <tr id="ss-tbl-row-piv-{{assessment.rollNumber}}-{{row.taxYear}}" mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>
      </div>
    </div>

  </div>
</div>
