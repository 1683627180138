<h1 id="confirm-h1-title" mat-dialog-title>{{ data.title }}</h1>

<mat-divider></mat-divider>

@if (data.dialogUsage == ConfirmDialogUsageEnum.NEW_VERSION) {
  <div class="dialog-content" mat-dialog-content>
    <div class="new-version-container">
      <div class="left">
        <div class="logo">
          <img id="dc-logo" src="assets/img/geowarehouse-g-logo.png"/>
        </div>
        <div id="dc-version" class="version">
          <span>v{{ data.newVersion }}</span>
        </div>
      </div>
      <div class="right">
        @for (item of data.content; track $index) {
          <div>
            <mat-label id="confirm-label-{{item}}">{{ item }}</mat-label>
          </div>
        }
      </div>
    </div>
  </div>
} @else {
  <div class="dialog-content" mat-dialog-content>
    <div></div>
    @for (item of data.content; track $index) {
      <div>
        <mat-label id="confirm-label-{{item}}">{{ item }}</mat-label>
      </div>
    }
    <div></div>
  </div>
}


<mat-divider></mat-divider>

<div align="end" mat-dialog-actions>
  @if (data.showFirstButton) {
    <button id="confirm-btn-first-button"
            type="button"
            mat-button
            mat-dialog-close
            class="dialog-accept-btn"
            (click)="onFirstButtonClick()"
    >
      {{ data.firstButtonText }}
    </button>
  }
  <button id="confirm-btn-second-button"
          type="button"
          mat-button
          mat-dialog-close
          class="dialog-close-btn"
          (click)="onSecondButtonClick()"
  >
    {{ data.secondButtonText }}
  </button>
</div>
